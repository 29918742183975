import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "@store/atom";
import { useTranslation } from "react-i18next";
import styles from "@styles/dataShare/device.module.css";
import { RemoteComponent } from "@/RemoteComponent";
import {TreeResData} from "@type/tree";
import {Props} from "@type/props";

const Device = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:analysisData"),
      subTitle: t("main:header:device"),
      path: "/analysisData/device",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (data: TreeResData) => {
    console.log("TreeResData", data);
  };

  const treeProps: Props = {
    onSelect: handleSelect,
  };

  return (
    <div className={styles.template}>
      <div>
        <div className={styles.title}>Tree</div>
        <div style={{ background: "#fff" }} className={styles.border}>
          <RemoteComponent {...treeProps} />
        </div>
      </div>
    </div>
  );
};

export default Device;
