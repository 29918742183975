import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const MixChart = ({ data, label, width, height, dataLabelEnable }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);
  const chartOptions: ApexOptions = {
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: dataLabelEnable,
      //   enabled: true,
      //   enabledOnSeries: [1],
    },
    labels: label,
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
  };

  useEffect(() => {
    setSeries(data);
  }, [data]);

  return (
    series && (
      <div
        style={{
          width: "100%",
          // height: "100%"
          display: "flex",
          alignItems: "center",
        }}
      >
        <Chart
          style={{
            width: "100%",
            // height: "100%",
          }}
          overflow="hidden"
          height={height}
          options={chartOptions}
          type="line"
          series={series}
        />
      </div>
    )
  );
};

export default MixChart;
