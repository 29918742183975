import React, { useEffect, useState } from "react";
import { errorSearchState, headerState } from "../../store/atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import type { PaginationProps } from "antd";
import service from "@services/errorLogService";
import ErrorLogSearchComponent from "./ErrorLogSearchComponent";
import dayjs from "dayjs";
import ErrorLogFullTextModal from "./ErrorLogFullTextModal";

const ErrorLogHistory = () => {
  const { t } = useTranslation();

  const textCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }
    if (a !== null && b === null) {
      return -1;
    }
    if (a === null && b !== null) {
      return 1;
    }
    const result = a.localeCompare(b);
    return result;
  };

  const dateCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }
    if (a !== null && b === null) {
      return -1;
    }
    if (a === null && b !== null) {
      return 1;
    }

    const unixA = dayjs(a).valueOf();
    const unixB = dayjs(b).valueOf();

    if (unixA === unixB) return 0;
    if (unixA > unixB) {
      return 1;
    } else {
      return -1;
    }
  };

  const sendCol: any = [
    {
      align: "right",
      width: "40px",
      title: "No.",
      dataIndex: "key",
    },
    {
      align: "left",
      title: t("main:shareData:sendingCompany"),
      dataIndex: "trnsUserNm",
      key: "trnsUserNm",
      sorter: (a: any, b: any) => textCompare(a.trnsUserNm, b.trnsUserNm),
    },
    {
      align: "left",
      title: t("main:shareData:sendingSystem"),
      dataIndex: "trnsSysNm",
      key: "trnsSysNm",
      sorter: (a: any, b: any) => textCompare(a.trnsSysNm, b.trnsSysNm),
    },
    {
      align: "left",
      title: t("main:shareData:receivingCompany"),
      dataIndex: "rcptnUserNm",
      key: "rcptnUserNm",
      sorter: (a: any, b: any) => textCompare(a.rcptnUserNm, b.rcptnUserNm),
    },
    {
      align: "left",
      title: t("main:shareData:receivingSystem"),
      dataIndex: "rcptnSysNm",
      key: "rcptnSysNm",
      sorter: (a: any, b: any) => textCompare(a.rcptnSysNm, b.rcptnSysNm),
      ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:datasetName"),
      dataIndex: "datasetName",
      key: "datasetName",
      sorter: (a: any, b: any) => textCompare(a.datasetName, b.datasetName),
      ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:datasetId"),
      dataIndex: "datasetId",
      key: "datasetId",
      sorter: (a: any, b: any) => textCompare(a.datasetId, b.datasetId),
    },
    {
      align: "left",
      title: t("main:shareData:sharedDataId"),
      dataIndex: "dataSharId",
      key: "dataSharId",
      sorter: (a: any, b: any) => textCompare(a.dataSharId, b.dataSharId),
    },
    {
      width: "100px",
      align: "left",
      title: t("main:shareData:sendingType"),
      dataIndex: "trnsMthd",
      key: "trnsMthd",
      sorter: (a: any, b: any) => textCompare(a.trnsMthd, b.trnsMthd),
    },
    {
      align: "center",
      title: t("main:shareData:sendingDate"),
      dataIndex: "trnsDt",
      key: "trnsDt",
      sorter: (a: any, b: any) => dateCompare(a.trnsDt, b.trnsDt),
      render: (record: any) => {
        return <div>{dayjs(record).format("YYYY-MM-DD HH:mm:ss")}</div>;
      },
    },
    {
      width: "100px",
      align: "left",
      title: t("main:shareData:receivingType"),
      dataIndex: "rcptnMthd",
      key: "rcptnMthd",
      sorter: (a: any, b: any) => textCompare(a.rcptnMthd, b.rcptnMthd),
    },
    {
      width: "300px",
      align: "center",
      title: t("main:shareData:errorMessage"),
      dataIndex: "errorMessage",
      key: "errorMessage",
      sorter: (a: any, b: any) => dateCompare(a.rcptnDt, b.rcptnDt),
      render: (record: string) => {
        return <div>{record}</div>;
      },
    },
    {
      width: "300px",
      align: "left",
      title: t("main:shareData:fullText"),
      dataIndex: "fullText",
      key: "fullText",
      sorter: (a: any, b: any) => textCompare(a.status, b.status),
      render: (fullText: string, record: any) => {
        return (
          <ErrorLogFullTextModal
            errorMessage={record.errorMessage}
            trnsDt={record.trnsDt}
            text={fullText}
          />
        );
      },
    },
  ];

  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:shareData"),
      subTitle: t("main:header:errorLogHistory"),
      path: "/shareData/errorlog",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchCondition, setSearchCondition] =
    useRecoilState(errorSearchState);

  const [dataList, setDataList] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(100);

  const onSearch = () => {
    getErrorLogList();
  };

  const getErrorLogList = () => {
    const payload = { ...searchCondition, page, size };
    // delete payload.endDateTime;
    // delete payload.startDateTime;
    delete payload.status;
    service.getErrorLogList(payload).then((res) => {
      const data = res.data.list;
      setTotalCount(res.data.totalCount);
      const _size = res.data.pageSize;
      setSize(res.data.pageSize);
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 + (page - 1) * _size };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber);
    setSearchCondition({ ...searchCondition, page: pageNumber, size });
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    setSize(pageSize);
    setSearchCondition({ ...searchCondition, page: current, size: pageSize });
  };

  useEffect(() => {
    getErrorLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, page]);

  return (
    <div>
      <ErrorLogSearchComponent onSearch={onSearch} />
      <Table
        className="dataShare"
        style={{ marginTop: "10px" }}
        dataSource={dataList}
        columns={sendCol}
        size="small"
        pagination={{
          onShowSizeChange: onShowSizeChange,
          current: page,
          onChange: onChange,
          total: totalCount,
          defaultCurrent: 1,
          pageSize: size,
          showSizeChanger: true,
        }}
      />
    </div>
  );
};

export default ErrorLogHistory;
