import React, { useEffect, useState } from "react";
import { ConfigProvider, Modal, Tooltip } from "antd";
import service from "@services/dataShareService";
import { useTranslation } from "react-i18next";
import { JSONTree } from "react-json-tree";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { ShareHistoryTransData, ShareHistoryTransDetailRes } from "@pages/shareData/history/history";
import styles from "@styles/dataShare/dataDetailModal.module.css";

interface Props {
  visible: boolean;
  handleOk: () => void;
  title: string;
  transactionId: string;
  param: ShareHistoryTransData;
}

const DataDetailModal = ({
                           visible,
                           handleOk,
                           title,
                           transactionId,
                           param,
                         }: Props) => {
  const { t } = useTranslation();

  const [resData, setResData] = useState<ShareHistoryTransDetailRes>();
  const [renderedData, setRenderedData] = useState<JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState<string>("");
  const [isError, setIsError] = useState(false);

  const getData = async (transactionId: string) => {
    setIsLoading(true);
    setMessage("");
    const payload = {
      transactionId: transactionId,
      datasetId: param.datasetId,
    };

    try {
      const res = await service.getTransactionDetailInfo(payload);
      console.log(res.data.data);
      if(res.data?.data && Object.keys(res.data.data).length > 0) {
         setResData(res.data);
       } else {
         setMessage(res.data.message || t("main:message:DataHasBeenDeleted"));
       }
    } catch (err: any) {
      console.error(err);
      setMessage(err.message || t("main:message:errorFetchingData"));
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (transactionId && param.datasetId) {
      getData(transactionId);
    }
  }, [transactionId, param.datasetId]);

  useEffect(() => {
    if (resData) {
      const rendered = renderData(resData);
      setRenderedData(rendered);
    }
  }, [resData]);

  const renderData = (resData: ShareHistoryTransDetailRes) => {
    const { meta, data } = resData;
    return (
      <div style={{ overflow: 'auto', maxHeight: '70vh', marginLeft: '20px'}}>
        <JSONTree
          data={data}
          theme={{
            base00: '#31344e',
            base09: '#7cafff',
            base0B: '#7af8ca',
            base0D: '#c8d3f5',
          }}
          shouldExpandNodeInitially={() => true}
          labelRenderer={([key]) => {
            const metaValue = meta[key] || key;
            return (
              <span>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgSpotlight: '#ffffff',
                      colorTextLightSolid: '#000000',
                    },
                  }}
                >
                  <Tooltip title={metaValue}>
                    {key}
                  </Tooltip>
                </ConfigProvider>
              </span>
            );
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      title={`[ ${title} ] ${t("main:shareData:dataDetailPopup")}`}
      open={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={false}
      width={'50vw'}
      styles={{
        body: { overflowY: 'auto', minHeight: '70vh', backgroundColor: '#31344e' },
      }}
    >
      <div className={styles.modalBody}>
        {isLoading ? (
          <LoadingSpinner />
        ) : message ? (
          <div className={`${styles.message} ${isError && styles.error}`}>{message}</div>
        ) : (
          renderedData
        )}
      </div>
    </Modal>
  );
};

export default DataDetailModal;