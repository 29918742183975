import React, { useEffect, useState } from "react";
import styles from "@styles/carbon/allProcess.module.css";
import MCsu from "./svg/MCsu";
import MGtsu from "./svg/MGtsu";

interface iProps {
  type: String;
  data: iProcessData | undefined;
  title: String;
}

interface iProcessData {
  nowPower: number;
  accPower: number;
  eAmont1: number;
  eAmont2: number;
  eAmont3: number;
}

const ProcessSum = ({ type, data, title }: iProps) => {
  const [sumData, setSumData] = useState<any>({
    nowPower: 0,
    accPower: 0,
    eAmont1: 0,
    eAmont2: 0,
    eAmont3: 0,
  });

  const dataFormat = () => {
    let _sumData: any = {};
    for (let d in data) {
      _sumData[d] = data[d].toFixed(2);
    }
    setSumData(_sumData);
  };

  useEffect(() => {
    if (!data) return;
    dataFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.processsum}>
      {type === "unload" ? (
        sumData && (
          <div className={styles.unload}>
            <div>
              <div className={styles.title}>{title}</div>
              <div className={styles.unloadSvg}>
                {title === "CSU" ? <MCsu /> : <MGtsu />}
              </div>
            </div>
            <div>
              <div className={styles.power}>
                <div>Power(now) : {sumData["nowPower"] || "0.00"} Kw</div>
                <div>Power(acc) : {sumData["accPower"] || "0.00"} Kw</div>
              </div>
              <div className={styles.emissionContainer}>
                <div className={`${styles.circle} ${styles.pink}`}>
                  <div>
                    CO<span style={{ fontSize: "10px" }}>2</span>
                  </div>
                  <div>{sumData["eAmont1"] || "0.00"}</div>
                </div>
                <div className={`${styles.circle} ${styles.blue}`}>
                  <div>
                    CH<span style={{ fontSize: "10px" }}>2</span>
                  </div>
                  <div>{sumData["eAmont2"] || "0.00"}</div>
                </div>
                <div className={`${styles.circle} ${styles.green}`}>
                  <div>
                    N<span style={{ fontSize: "10px" }}>2</span>O
                  </div>
                  <div>{sumData["eAmont3"] || "0.00"}</div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <div className={styles.title}>{title} ALL</div>
          <div className={styles.power}>
            <div>Power(now) : {sumData["nowPower"] || "0.00"} Kw</div>
            <div>Power(acc) : {sumData["accPower"] || "0.00"} Kw</div>
          </div>
          <div className={styles.emissionContainer}>
            <div className={`${styles.circle} ${styles.pink}`}>
              <div>
                CO<span style={{ fontSize: "10px" }}>2</span>
              </div>
              <div>{sumData["eAmont1"] || "0.00"}</div>
            </div>
            <div className={`${styles.circle} ${styles.blue}`}>
              <div>
                CH<span style={{ fontSize: "10px" }}>2</span>
              </div>
              <div>{sumData["eAmont2"] || "0.00"}</div>
            </div>
            <div className={`${styles.circle} ${styles.green}`}>
              <div>
                N<span style={{ fontSize: "10px" }}>2</span>O
              </div>
              <div>{sumData["eAmont3"] || "0.00"}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProcessSum;
