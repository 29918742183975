import React, { useEffect, useState } from "react";
import styles from "@styles/carbon/allProcess.module.css";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

interface iProps {
  tData: iProcessData | undefined;
  showChartModal: () => void;
}

interface iProcessData {
  nowPower: number;
  accPower: number;
  eAmont1: number;
  eAmont2: number;
  eAmont3: number;
}

const STD_CO2 = 500;
const STD_CH4 = 500;
const STD_N2O = 100;

const SumComponent = ({ tData, showChartModal }: iProps) => {
  const [sumData, setSumData] = useState<any>({
    nowPower: 0.0,
    accPower: 0.0,
    eAmont1: 0.0,
    eAmont2: 0.0,
    eAmont3: 0.0,
    eSumAmont: 0.0,
  });

  const dataFormat = (tData: any) => {
    if (tData === undefined) return;
    let _sumData: any = {};

    for (let d in tData) {
      _sumData[d] = tData[d].toFixed(2);
    }
    _sumData["eSumAmont"] =
      tData["eAmont1"] +
      (tData["eAmont2"] * 21 + tData["eAmont3"] * 310) * 0.001;
    _sumData["eSumAmont"] = _sumData["eSumAmont"].toFixed(2);
    setSumData(_sumData);
  };

  useEffect(() => {
    dataFormat(tData);
  }, [tData]);

  return (
    <>
      <div className={styles.sumComponent}>
        <div
          className={`${styles.hover} ${styles.div1}`}
          onClick={() => {
            showChartModal();
          }}
        >
          <div>ALL EMISSION</div>
          <div>
            <span>CO2eq:</span>
            {sumData["eSumAmont"]}
          </div>
        </div>
        <div className={styles.div2}>
          <div>POWER</div>
          <div> {sumData["nowPower"]}</div>
        </div>
      </div>
      <div className={styles.emissionDiv}>
        <div>
          <div>
            <span>
              CO<span style={{ fontSize: "10px" }}>2</span>:
            </span>
            {sumData["eAmont1"]}
          </div>
          <div>
            <span>
              {sumData["eAmont1"] > STD_CO2 ? (
                <CaretUpOutlined className={styles.iconUp} />
              ) : (
                <CaretDownOutlined className={styles.iconDown} />
              )}
              {"(" + ((sumData["eAmont1"] / STD_CO2) * 100).toFixed(2) + "%)"}
            </span>
          </div>
        </div>
        <div>
          <div>
            <span>
              CH<span style={{ fontSize: "10px" }}>4</span>:{" "}
            </span>
            {sumData["eAmont2"]}
          </div>
          <div>
            <span>
              {sumData["eAmont2"] > STD_CH4 ? (
                <CaretUpOutlined className={styles.iconUp} />
              ) : (
                <CaretDownOutlined className={styles.iconDown} />
              )}
              {"(" + ((sumData["eAmont2"] / STD_CH4) * 100).toFixed(2) + "%)"}
            </span>
          </div>
        </div>
        <div>
          <div>
            <span>
              N<span style={{ fontSize: "10px" }}>2</span>
              O:
            </span>
            {sumData["eAmont3"]}
          </div>
          <div>
            <span>
              {sumData["eAmont3"] > STD_N2O ? (
                <CaretUpOutlined className={styles.iconUp} />
              ) : (
                <CaretDownOutlined className={styles.iconDown} />
              )}
              {"(" + ((sumData["eAmont3"] / STD_N2O) * 100).toFixed(2) + "%)"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SumComponent;
