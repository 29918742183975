import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import { Table, Button, Modal, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import data from "../../../data/index.json";
import ModifyModal from "./ModifyModal";
import service from "@services/carbontaxService";
const columns: any = data["carbonTax"]["emissionInfo"]["columns"];
const dataSource: any = data["carbonTax"]["emissionInfo"]["dataSource"];

export interface EmissionData {
  regDate: string;
  serialNum: string;
  processNm: string;
  facNm: string;
  emissionAct: string;
  activityData: string;
  unit: string;
  sum: number;
  uncertainty: number;
  desc: string;
}

const EmissionInfo = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:emissionInfo"),
      path: "/carbonTax/emissionInfo",
    });
  }, []);

  const [emissionData, setEmissionData] = useState<EmissionData[]>(null);
  const [selectData, setSelectData] = useState<any>(null);
  const [isModify, setIsModify] = useState<boolean>(false);

  useEffect(() => {
    service.getEmission().then((res: any) => {
      const data = res.data.result;
      setEmissionData(data);
    });
  }, []);

  const getEmissionUpdate = (payload: any) => {
    service.getEmissionUpdate(payload).then((res: any) => {
      if (res.result.statue === 200) {
        message.success("배출 데이터가 변경 되었습니다.");
      } else {
        message.error("배출 데이터가 변경 되지 않았습니다.");
      }
      setIsModify(false);
    });
  };

  const getEmissionDelete = (payload: any) => {
    service.getEmissionDelete(payload).then((res: any) => {
      if (res.result.statue === 200) {
        message.success("배출 데이터가 삭제 되었습니다.");
      } else {
        message.error("배출 데이터가 삭제 되지 않았습니다.");
      }
    });
  };

  const handleCancel = () => {
    setIsModify(false);
  };

  const handleOk = (factor: any) => {
    getEmissionUpdate(factor);
  };

  const onCreate = () => {
    setIsModify(true);
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: "이 배출원 정보를 정말로 삭제 하시겠습니까?",
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <ul style={{ fontSize: "14px", fontWeight: "700" }}>
            <li>타입 : {selectData.type}</li>
            <li>내부 시설 명 : {selectData.facilityNm}</li>
          </ul>
        </>
      ),
      okText: "예",
      cancelText: "아니오",
      onOk() {
        getEmissionDelete(selectData.emissionId);
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectData(selectedRows[0]);
    },
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          marginBottom: "20px",
        }}
      >
        <div>
          <Button
            type="primary"
              className="searchbtn"
            onClick={onCreate}
            disabled={selectData !== null}
          >
            생성
          </Button>
          <Button
            type="primary"
              className="searchbtn"
            onClick={onCreate}
            style={{ marginLeft: "10px" }}
            disabled={selectData === null}
          >
            수정
          </Button>
          <Button
            type="primary"
              className="searchbtn"
            style={{ marginLeft: "10px" }}
            onClick={showConfirm}
            disabled={selectData === null}
          >
            삭제
          </Button>
        </div>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        dataSource={dataSource}
        // dataSource={emissionData}
        columns={columns}
      />
      {isModify && (
        <ModifyModal
          visible={isModify}
          handleCancel={handleCancel}
          handleOk={handleOk}
          obj={selectData}
        />
      )}
    </div>
  );
};

export default EmissionInfo;
