import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const LineBasicChart = (props: any) => {
  const { height } = props;
  const [option, setOption] = useState<any>(null);

  const getOption = (prop: any) => {
    const chartOptions: ApexOptions = {
      title: {
        text: prop.title,
        align: "left",
        style: {
          fontSize: "24px",
          fontWeight: "normal",
          fontFamily: "Noto Sans KR",
          color: "#F0F0F0",
        },
      },

      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Noto Sans KR",
        },
        y: {
          formatter: function (val) {
            return val.toFixed(6);
          },
        },
      },
      markers: { size: 5 },
      chart: {
        zoom: {
          enabled: false,
        },

        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 4,
      },
      xaxis: {
        categories: prop.category,
        labels: {
          style: {
            fontFamily: "Noto Sans KR",
            colors: "#F0F0F0",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val.toFixed(2);
          },
          style: {
            colors: "#F0F0F0",
            fontSize: "16px",
            fontFamily: "Noto Sans KR",
          },
        },
      },
      series: prop.data,
      legend: {
        offsetY: 10,
        show: true,
        position: "top",
        horizontalAlign: "right",
        fontFamily: "Noto Sans KR",
        fontSize: "16px",
        labels: {
          colors: "#F0F0F0",
        },
        itemMargin: {
          horizontal: 5,
        },
      },
      colors: ["#0487D9", "#FFF466", "#E30039", "#01AB33"],
    };

    setOption(chartOptions);
  };

  useEffect(() => {
    if (props) {
      getOption(props);
    }
  }, [props]);

  return (
    <div
      style={{
        borderRadius: "10px",
        background: "#105780",
      }}
    >
      {option && (
        <Chart
          options={option}
          type="line"
          series={option.series}
          height={height}
        />
      )}
    </div>
  );
};

export default LineBasicChart;
