import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {headerState} from "@store/atom";
import {useTranslation} from "react-i18next";
import {ConfigProvider, Table} from "antd";
import styles from "@styles/dataShare/metric.module.css";
import service from "@services/dataShareService";
import {ColumnType} from "antd/es/table";

interface iDataset {
  dtsetUuid: string;
  dtsetExpl: string;
  dtsetNm: string;
}

interface iDatasetDetail {
	dtsetItemUuid: string;
  dataDcmlLenh: string;
	dataLenh: string;
	dataType: string;
	dtsetItemEngNm: string;
	dtsetItemNm: string;
	dtsetItemSeq: string;
	grpSeCd: string;
}

const Metric = () => {
  const { t } = useTranslation();

  const columns1 :ColumnType<iDataset> [] = [
    {
      title: t("main:shareData:datasetUuid"),
      dataIndex: "dtsetUuid",
      key: "dtsetUuid",
    },
    {
      title: t("main:shareData:datasetName"),
      dataIndex: "dtsetNm",
      key: "dtsetNm",
    },
  ];

  const columns2:ColumnType<iDatasetDetail> [] = [
    {
      dataIndex: "dtsetItemSeq",
      title: t("main:shareData:sequenceNumber"),
      align: "center",
    },
    {
      dataIndex: "dtsetItemNm",
      title: t("main:shareData:korean"),
    },
    {
      dataIndex: "dtsetItemEngNm",
      title: t("main:shareData:english"),
    },
    {
      dataIndex: "dataType",
      title: t("main:shareData:dataType"),
    },
    {
      dataIndex: "dataLenh",
      title: t("main:shareData:dataLength"),
      align: "center",
    },
    {
      dataIndex: "dataDcmlLenh",
      title: t("main:shareData:decimalLength"),
      align: "center",
    },
    {
      dataIndex: "grpSeCd",
      title: t("main:shareData:groupSeparatorCode"),
      align: "center",
    },
  ];

  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:analysisData"),
      subTitle: t("main:header:metric"),
      path: "/analysisData/metric",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [datasetList, setDatasetList] = useState<iDataset[]>();
  const [datasetDetail, setDatasetDetail] = useState<iDatasetDetail[]>();
  const [tHeight, setTHeight] = useState<any>(0);

  const getDatasetList = () => {
    service.getDatasetList().then((res) => {
      const data = res.data.list;
      const id = data[0]["dtsetUuid"];
      let _r = [];

      for (let i in data) {
        const _data = { ...data[i], key: Number(i) };
        _r.push(_data);
      }
      setDatasetList(_r);
      getDatasetDetail(id);
    });
  };

  const getDatasetDetail = (id: string) => {
    service.getDatasetDetail(id).then((res) => {
      setDatasetDetail(res.data.list);
      setSelectedRowKey(id)
    });
  };

  useEffect(() => {
    getDatasetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tHeight = window.innerHeight - 228;
    setTHeight(tHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedRowKey, setSelectedRowKey] = useState(null); // Add state to keep track of selected row

  return (
    <div className={styles.template}>
      <div className={styles.div1}>
        <div className={styles.title}>{t("main:shareData:metricList")}</div>
        <div className={styles.border}>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  rowHoverBg: '#dcd1ca',
                },
              },
            }}
          >
          <Table
                size={'small'}
                dataSource={datasetList}
                columns={columns1}
                pagination={false}
                scroll={{ y: tHeight }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      getDatasetDetail(record.dtsetUuid);
                      setSelectedRowKey(record.dtsetUuid);
                    },
                  };
                }}
                rowClassName={(record) =>
                  record.dtsetUuid === selectedRowKey
                    ? styles.dtsetSelectedRow
                    : styles.dtsetRow
                }
            />
          </ConfigProvider>
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.title}>
          {t("main:shareData:SendReceiveData")}
        </div>
        <div className={styles.border}>
            <Table
              size={'small'}
              rowKey={(record) => record.dtsetItemUuid}
              dataSource={datasetDetail}
              columns={columns2}
              pagination={false}
              scroll={{ y: tHeight }}
            />
        </div>
      </div>
    </div>
  );
};

export default Metric;
