import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Table } from "antd";
import dayjs from "dayjs";
import service from "@services/dataShareService";
import type { PaginationProps } from "antd";

const DataDetailModal = ({ visible, handleOk, title, param }: any) => {
  const { t } = useTranslation();

  const setStatus = (data: string) => {
    let result = "";
    switch (data) {
      case "":
        result = "전체";
        break;
      case null:
        result = "-";
        break;
      case "Y":
        result = "완료";
        break;
      case "N":
        result = "수집중";
        break;
      default:
        result = "-";
        break;
    }
    return result;
  };

  const columns: any = [
    { align: "right", width: "40px", title: "No.", dataIndex: "key" },
    {
      align: "left",
      title: t("main:shareData:sharedDataId"),
      dataIndex: "transactionId",
      key: "transactionId",
      // ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:sendingCompany"),
      dataIndex: "trnsUserNm",
      key: "trnsUserNm",
    },
    {
      align: "left",
      title: t("main:shareData:sendingSystem"),
      dataIndex: "trnsSysNm",
      key: "trnsSysNm",
    },
    {
      align: "left",
      title: t("main:shareData:receivingCompany"),
      dataIndex: "rcptnUserNm",
      key: "rcptnUserNm",
    },
    {
      align: "left",
      title: t("main:shareData:receivingSystem"),
      dataIndex: "rcptnSysNm",
      key: "rcptnSysNm",
    },
    {
      align: "left",
      title: t("main:shareData:datasetName"),
      dataIndex: "datasetName",
      key: "datasetName",
    },
    {
      align: "left",
      title: t("main:shareData:datasetId"),
      dataIndex: "datasetId",
      key: "datasetId",
    },
    {
      align: "left",
      title: t("main:shareData:sendingType"),
      dataIndex: "trnsMthd",
      key: "trnsMthd",
    },
    {
      align: "center",
      title: t("main:shareData:sendingDate"),
      dataIndex: "trnsDt",
      key: "trnsDt",
      render: (record: any) => {
        return (
          <div>
            {dayjs(record).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      align: "left",
      title: t("main:shareData:receivingType"),
      dataIndex: "rcptnMthd",
      key: "rcptnMth                                                                                                                                                                                                                                                       d",
    },
    {
      align: "center",
      title: t("main:shareData:receivingDate"),
      dataIndex: "rcptnDt",
      key: "rcptnDt",
      render: (record: any) => {
        let date = "-";
        if (record) {
          date = dayjs(record).format("YYYY-MM-DD HH:mm:ss");
        }
        return (
          <div>
            {date}
          </div>
        );
      },
    },
    {
      align: "left",
      title: t("main:shareData:receivingStatus"),
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        return (
          <div>
            {setStatus(record)}
          </div>
        );
      },
    },
  ];

  const [dataList, setDataList] = useState<any>();

  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    const datasetName = param.name;
    // console.log("@@@@ param.dateType", param.dateType);
    if (param.dateType === "today") {
      getDataCountDaliyList(datasetName);
    } else {
      getDataCounWeekList(datasetName);
    }
  }, [size, page]);

  const getDataCountDaliyList = (datasetName: string) => {
    const payload = {
      datasetName,
      page,
      size,
    };

    service.getDataCountDaliyList(payload).then((res) => {
      const data = res.data.list;
      setTotalCount(res.data.totalCount);
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 + (page - 1) * size };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const getDataCounWeekList = (datasetName: string) => {
    const payload = {
      datasetName,
      page,
      size,
    };

    service.getDataCounWeekList(payload).then((res) => {
      const data = res.data.list;
      setTotalCount(res.data.totalCount);
      const _size = res.data.pageSize;
      setSize(res.data.pageSize);
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 + (page - 1) * _size };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    setPage(current);
    setSize(pageSize);
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={false}
      width={1500}
    >
      <Table
        className="dataShare"
        style={{ marginTop: "10px" }}
        dataSource={dataList}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          onShowSizeChange: onShowSizeChange,
          current: page,
          onChange: onChange,
          total: totalCount,
          defaultCurrent: 1,
          pageSize: size,
        }}
        size="small"
      />
    </Modal>
  );
};

export default DataDetailModal;
