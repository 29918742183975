import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {authSelector} from "@store/atom";
import {AuthService} from "@services/AuthService";
import "./App.css";
import Loading from "@components/Loading";
import DefaultLayout from "./DefaultLayout";
import useSetupAxiosInterceptor from "@utils/useSetupAxiosInterceptor";
import {tokenDecoder} from "@utils/tokenDecoder";

function App() {
  useSetupAxiosInterceptor();

  const [auth, setAuth] = useRecoilState(authSelector);
  const { isLogin } = useRecoilValue<any>(authSelector);
  const [isLoading, setIsLoading] = useState(true);

  const refreshAuth = async () => {
    try {
      setIsLoading(true);
      const res = await AuthService.refresh();
      const result = tokenDecoder(res?.data);
      setAuth(result);
    } catch (e) {
      localStorage.clear();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLogin) {
      refreshAuth();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return <DefaultLayout />;
  }
}

export default App;
