import React, { useEffect, useState } from "react";
import styles from "@styles/carbon/allProcess.module.css";
import ProcessSum from "./ProcessSum";
import SumComponent from "./SumComponent";
import EqpList from "./EqpList";
import DonutChart from "./chart/DonutChart";
import { LoadingOutlined } from "@ant-design/icons";
import ChartModal from "./ChartModal";
import EqpChartModal from "./EqpChartModal";

const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 48, color: "gray" }} spin />
);

const unloadListdummy = [
  {
    eqpType: "CSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU101",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU102",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU103",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU104",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU105",
        facNm: "CSU",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "CSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU106",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU107",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU108",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU109",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU110",
        facNm: "CSU",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "CSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU111",
        facNm: "CSU",
        status: "idle",
      },
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  },
  {
    eqpType: "GTSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU101",
        facNm: "GTSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU102",
        facNm: "GTSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU103",
        facNm: "GTSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU104",
        facNm: "GTSU",
        status: "idle",
      },
      undefined,
    ],
  },
];
const exportListdummy = [
  {
    eqpType: "BC",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "BC301",
        facNm: "BC",
        status: "idle",
      },
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  },
  {
    eqpType: "RE",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE301",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE302",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE303",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE304",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE305",
        facNm: "RE",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "RE",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE306",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE307",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE308",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE309",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE310",
        facNm: "RE",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "RE",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE31",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE312",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE313",
        facNm: "RE",
        status: "idle",
      },
      undefined,
      undefined,
    ],
  },
];
const importListdummy = [
  {
    eqpType: "ST",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST201",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST202",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST203",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST204",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST205",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "ST",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST206",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST207",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST208",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST209",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST210",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "ST",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST211",
        facNm: "ST",
        processNm: "importing",
        status: "idle",
      },
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  },
  {
    eqpType: "BC",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "BC201",
        facNm: "BC",
        processNm: "importing",
        status: "idle",
      },
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  },
];
const blendListdummy = [
  {
    eqpType: "RE",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE401",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE402",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE403",
        facNm: "RE",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "RE404",
        facNm: "RE",
        status: "idle",
      },
      undefined,
    ],
  },
  {
    eqpType: "ST",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST401",
        facNm: "ST",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST402",
        facNm: "ST",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST403",
        facNm: "ST",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "ST404",
        facNm: "ST",
        status: "idle",
      },
      undefined,
    ],
  },
  {
    eqpType: "ST",
    value: [undefined, undefined, undefined, undefined, undefined],
  },
  {
    eqpType: "BC",
    value: [undefined, undefined, undefined, undefined, undefined],
  },
];

const AllProcess = ({
  totalProcessData,
  emissionChartData,
  powerChartData,
  accPowerChartData,
  unloadSumData,
  importSumData,
  exportSumData,
  blendingSumData,
  unloadData,
  importData,
  exportData,
  blendingData,
}: any) => {
  const [unloadList, setUnloadList] = useState<any>(null);
  const [importList, setImportList] = useState<any>(null);
  const [exportList, setExportList] = useState<any>(null);
  const [blendList, setBlendList] = useState<any>(null);
  const [isChart, setIsChart] = useState<boolean>(false);
  const [isEqpChart, setIsEqpChart] = useState<boolean>(false);
  const [eqpName, setEqpName] = useState<String>();

  const showChartModal = () => {
    setIsChart(true);
  };

  const handleOk = () => {
    setIsChart(false);
    setIsEqpChart(false);
  };

  useEffect(() => {
    let unload: any = [];
    if (!unloadData) return;
    for (let index = 0; index < 3; index++) {
      let value = [];
      for (let i = 0; i < 5; i++) {
        const data = unloadData["unloading CSU"][index * 5 + i];
        value.push(data);
      }
      unload.push({ eqpType: "csu", value });
    }
    let value = [];
    for (let index = 0; index < 5; index++) {
      const data = unloadData["unloading GTSU"][index];
      value.push(data);
    }
    unload.push({ eqpType: "gtsu", value });
    setUnloadList(unload);
  }, [unloadData]);

  useEffect(() => {
    let _import: any = [];
    if (!importData) return;
    for (let index = 0; index < 3; index++) {
      let value = [];
      for (let i = 0; i < 5; i++) {
        const data = importData["importing ST"][index * 5 + i];
        value.push(data);
      }
      _import.push({ eqpType: "ST", value });
    }
    let value = [];
    for (let index = 0; index < 5; index++) {
      const data = importData["importing BC"][index];
      value.push(data);
    }
    _import.push({ eqpType: "BC", value });
    setImportList(_import);
  }, [importData]);

  useEffect(() => {
    let _export: any = [];
    if (!exportData) return;
    let value = [];
    for (let index = 0; index < 5; index++) {
      const data = exportData["exporting BC"][index];
      value.push(data);
    }

    _export.push({ eqpType: "BC", value });

    for (let index = 0; index < 3; index++) {
      let value = [];
      for (let i = 0; i < 5; i++) {
        const data = exportData["exporting RE"][index * 5 + i];
        value.push(data);
      }
      _export.push({ eqpType: "RE", value });
    }

    setExportList(_export);
  }, [exportData]);

  useEffect(() => {
    let blend: any = [];
    if (!blendingData) return;

    for (let index = 0; index < 1; index++) {
      let value = [];
      for (let i = 0; i < 5; i++) {
        const data = blendingData["blending RE"][index * 5 + i];
        value.push(data);
      }
      blend.push({ eqpType: "RE", value });
    }

    for (let index = 0; index < 3; index++) {
      let value = [];
      for (let i = 0; i < 5; i++) {
        const data = blendingData["blending ST"][index * 5 + i];
        value.push(data);
      }
      blend.push({ eqpType: "RE", value });
    }

    setBlendList(blend);
  }, [blendingData]);

  return (
    <div className={styles.template}>
      <div className={styles.div1}>
        <div className={styles.processsumContainer}>
          <ProcessSum
            type={""}
            data={unloadSumData || undefined}
            title={"UNLOAD"}
          />
          <ProcessSum
            type={""}
            data={importSumData || undefined}
            title={"IMPORT"}
          />
          <ProcessSum
            type={""}
            data={exportSumData || undefined}
            title={"EXPORT"}
          />
          <ProcessSum
            type={""}
            data={blendingSumData || undefined}
            title={"BLENDING"}
          />
        </div>
        <div className={styles.sumContainer}>
          <SumComponent
            tData={totalProcessData || undefined}
            showChartModal={showChartModal}
          />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.processContainer}>
          <div className={styles.row1}>
            <div>
              <div className={styles.title}>하역</div>
              {!unloadList
                ? unloadListdummy.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))
                : unloadList.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))}
            </div>
            <div>
              <div className={styles.title}>반입</div>
              {!importList
                ? importListdummy.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))
                : importList.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))}
            </div>
          </div>
          <div className={styles.row1}>
            <div>
              <div className={styles.title}>반출</div>
              {!exportList
                ? exportListdummy.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))
                : exportList.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))}
            </div>
            <div>
              <div className={styles.title}>브랜딩</div>
              {!blendList
                ? blendListdummy.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))
                : blendList.map((eqp: any) => (
                    <EqpList
                      key={eqp.eqpName}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))}
            </div>
          </div>
        </div>
        <div className={styles.chartContainer}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {emissionChartData ? (
              <DonutChart
                key={"chart1"}
                title={"Emissions by Process"}
                data={emissionChartData.data}
                category={emissionChartData.category}
              />
            ) : (
              loadingIcon
            )}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {powerChartData ? (
              <DonutChart
                key={"chart2"}
                title={"Amount of Electricity by Process"}
                data={powerChartData.data}
                category={powerChartData.category}
              />
            ) : (
              loadingIcon
            )}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {accPowerChartData ? (
              <DonutChart
                key={"chart3"}
                title={"Accumulated Power by Process"}
                data={accPowerChartData.data}
                category={accPowerChartData.category}
              />
            ) : (
              loadingIcon
            )}
          </div>
        </div>
      </div>
      {isChart && (
        <ChartModal isModalOpen={isChart} handleOk={handleOk} processNm={""} />
      )}
      {isEqpChart && (
        <EqpChartModal
          isModalOpen={isEqpChart}
          handleOk={handleOk}
          eqpName={eqpName}
        />
      )}
    </div>
  );
};

export default AllProcess;
