import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { Table } from "antd";
import type { PaginationProps } from "antd";
import service from "@services/dataShareService";
import dayjs from "dayjs";

const UserInfo = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:shareData"),
      subTitle: t("main:header:userInfo"),
      path: "/shareData/userInfo",
    });
  }, []);

  const textCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }
    const result = a.localeCompare(b);
    return result;
  };

  const dateCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }

    const unixA = dayjs(a).valueOf();
    const unixB = dayjs(b).valueOf();

    if (unixA === unixB) return 0;
    if (unixA > unixB) {
      return 1;
    } else {
      return -1;
    }
  };

  const columns: any = [
    {
      align: "right",
      title: "사용자 ID",
      dataIndex: "userId",
      key: "userId",
      sorter: (a: any, b: any) => a.userId - b.userId,
    },
    {
      title: "로그인 ID",
      dataIndex: "lgnId",
      key: "lgnId",
      sorter: (a: any, b: any) => textCompare(a.lgnId, b.lgnId),
    },
    {
      title: "사용자 명",
      dataIndex: "userNm",
      key: "userNm",
      sorter: (a: any, b: any) => textCompare(a.userNm, b.userNm),
    },
    {
      title: "기업 코드",
      dataIndex: "entCd",
      key: "entCd",
      sorter: (a: any, b: any) => textCompare(a.entCd, b.entCd),
    },
    {
      title: "부서명",
      dataIndex: "deptNm",
      key: "deptNm",
      sorter: (a: any, b: any) => textCompare(a.deptNm, b.deptNm),
    },
    {
      align: "right",
      title: "산업 분류 코드",
      dataIndex: "indstClsfCd",
      key: "indstClsfCd",
      sorter: (a: any, b: any) => textCompare(a.indstClsfCd, b.indstClsfCd),
    },
    {
      align: "right",
      title: "사용자 전화번호",
      dataIndex: "userTelno",
      key: "userTelno",
      sorter: (a: any, b: any) => a.userTelno - b.userTelno,
      render: (record: any) => {
        return record
          .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
          .replace(/\-{1,2}$/g, "");
      },
    },

    {
      align: "right",
      title: "최초 생성 일시",
      dataIndex: "frstCrtDt",
      key: "frstCrtDt",
      sorter: (a: any, b: any) => dateCompare(a.frstCrtDt, b.frstCrtDt),
      render: (record: any) => {
        let date = "-";
        if (record) {
          date = dayjs(record).format("YYYY-MM-DD HH:mm:ss");
        }
        return (
          <div>
            {date}
          </div>
        );
      },
    },

    {
      align: "right",
      title: "최종 수정 일시",
      dataIndex: "lastMdfcnDt",
      key: "lastMdfcnDt",
      sorter: (a: any, b: any) => dateCompare(a.lastMdfcnDt, b.lastMdfcnDt),
      render: (record: any) => {
        let date = "-";
        if (record) {
          date = dayjs(record).format("YYYY-MM-DD HH:mm:ss");
        }
        return (
          <div>
            {date}
          </div>
        );
      },
    },
  ];

  const [dataList, setDataList] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  const getUserData = () => {
    service.getUserInfo(page, size).then((res) => {
      const data = res.data.list;
      const _size = res.data.pageSize;

      setTotalCount(res.data.totalCount);

      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 + (page - 1) * _size };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber);
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    setSize(pageSize);
  };

  useEffect(() => {
    getUserData();
  }, [size, page]);

  return (
    <Table
      className="dataShare"
      style={{ marginTop: "10px" }}
      dataSource={dataList}
      columns={columns}
      size="small"
      pagination={{
        onShowSizeChange: onShowSizeChange,
        current: page,
        onChange: onChange,
        total: totalCount,
        defaultCurrent: 1,
        pageSize: size,
      }}
    />
  );
};

export default UserInfo;
