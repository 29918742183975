import axios from "axios";

const apiUrl = process.env.REACT_APP_DX_API_URL;
const optFacService = {
  async getTagTrendTable(payload: any) {
    const response = await axios.post(`${apiUrl}/oms/tag-trend/data`, payload);
    return response;
  },

  async getTagTrend(payload: any) {
    const response = await axios.post(`${apiUrl}/oms/tag-trend/chart`, payload);
    return response;
  },
};

export default optFacService;
