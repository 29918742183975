import React from "react";

const MGtsuSvg = ({
  statusColor,
  titleColor,
  data,
  setIsEqpChart,
  setEqpName,
}) => {
  const showModal = () => {
    setIsEqpChart(true);
    setEqpName(data.eqpName);
  };

  return (
    <div
      onClick={() => {
        showModal();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        id="_\uB808\uC774\uC5B4_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // width="100px"
        // height="95px"
        // viewBox="0 0 65 50"
        width="130px"
        height="100px"
        viewBox="0 0 65 50"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`\n\t.gtsu${titleColor}{fill:${statusColor};stroke:${statusColor};stroke-width:0.5;}\n`}
        </style>
        <g id="_\uADF8\uB8F9_1002-3">
          <g id="_\uADF8\uB8F9_565-4">
            <path
              id="_\uD328\uC2A4_586-4"
              className={`gtsu${titleColor}`}
              d="M33.9,47.3H13.8c-0.9,0-1.6-0.8-1.6-1.7v-9.4h7.5v-0.1h-7.5v-0.8h8.5v2.1l-2.8,0.4v6.6 L19,45h9.8l1.2-0.7v-6.4l-3.1-0.4v-2.1h8.5v0.8h-7.5l0,0h7.5v9.4C35.2,46.2,34.9,47.3,33.9,47.3L33.9,47.3L33.9,47.3z M12.6,36.8 v9c0,0.7,0.5,1.2,1.2,1.2h20c0.9,0,1-1.2,1-1.3v-9h-7.5v-0.9h-0.1V37l3.1,0.4v7l-1.5,0.9h-10l-1.4-0.8v-7.2l2.8-0.4v-1.3H20v0.9 L12.6,36.8L12.6,36.8z"
            />
            <path
              id="_\uD328\uC2A4_587-4"
              className={`gtsu${titleColor}`}
              d="M36.1,44.5h-0.5v-9.1h22v0.4H36.1V44.5z"
            />
            <path
              id="_\uD328\uC2A4_588-4"
              className={`gtsu${titleColor}`}
              d="M36.1,39.6h-1.2v-2.4h1.2V39.6z M35.4,39.2h0.3v-1.6h-0.3V39.2z"
            />
          </g>
          <path
            id="_\uD328\uC2A4_589-4"
            className={`gtsu${titleColor}`}
            d="M57.8,24.3h-37v-1.8h37V24.3L57.8,24.3z M21.4,23.9h36.1v-1H21.4V23.9z"
          />
          <path
            id="_\uD328\uC2A4_590-4"
            className={`gtsu${titleColor}`}
            d="M39.1,35.8h-1.5V20.4h1.5V35.8z M38,35.4h0.6V20.8H38V35.4z"
          />
          <path
            id="_\uD328\uC2A4_591-4"
            className={`gtsu${titleColor}`}
            d="M52.1,35.8h-1.5V20.4h1.5V35.8z M51,35.4h0.6V20.8H51V35.4z"
          />
          <path
            id="_\uD328\uC2A4_592-4"
            className={`gtsu${titleColor}`}
            d="M54.7,22.9h-8.4v-6.2h8.4V22.9z M46.7,22.5h7.5v-5.4h-7.5V22.5z"
          />
          <path
            id="_\uD328\uC2A4_593-4"
            className={`gtsu${titleColor}`}
            d="M54.7,22.9h-8.4v-2.5h8.4V22.9z M46.7,22.5h7.5V21h-7.5V22.5z"
          />
          <path
            id="_\uD328\uC2A4_594-4"
            className={`gtsu${titleColor}`}
            d="M39.1,19.8h-1.5v-7.1h1.5V19.8z M38,19.3h0.6v-6.2H38V19.3z"
          />
          <path
            id="_\uD328\uC2A4_595-4"
            className={`gtsu${titleColor}`}
            d="M39.2,20.9h-1.7l0.9-1.4L39.2,20.9L39.2,20.9L39.2,20.9z M38.2,20.4h0.2l-0.1-0.1 L38.2,20.4L38.2,20.4z"
          />
          <path
            id="_\uD328\uC2A4_596-4"
            className={`gtsu${titleColor}`}
            d="M38.2,20.8l-0.8-1.4h1.7L38.2,20.8L38.2,20.8L38.2,20.8z M38.2,19.8L38.2,19.8h0.2H38.2 L38.2,19.8z"
          />
          <path
            id="_\uD328\uC2A4_597-4"
            className={`gtsu${titleColor}`}
            d="M24.6,31c-0.9,0-1.7-0.6-2.1-1.4l-0.1-0.2l2.3-0.9l2.2,0.8v0.2C26.5,30.4,25.6,31,24.6,31 L24.6,31L24.6,31z M23,29.6c0.3,0.6,0.9,0.9,1.6,1c0.7,0.1,1.4-0.3,1.7-1L24.6,29L23,29.6L23,29.6L23,29.6z"
          />
          <rect
            id="_\uC0AC\uAC01\uD615_247-4"
            x={24.4}
            y={24.1}
            className={`gtsu${titleColor}`}
            width={0.5}
            height={6.6}
          />
          <path
            id="_\uD328\uC2A4_639-4"
            className={`gtsu${titleColor}`}
            d="M25,22.5l12.7-9.8l0.3,0.3l-12.7,9.8L25,22.5z"
          />
          <path
            id="_\uD328\uC2A4_598-4"
            className={`gtsu${titleColor}`}
            d="M39,35.8l-0.3-0.3l11.8-12L38.7,13.1l0.3-0.3l12.1,10.7C51.1,23.4,39,35.8,39,35.8z"
          />
        </g>
      </svg>
    </div>
  );
};

export default MGtsuSvg;
