import React from "react";
import { Select, Button } from "antd";
import styles from "../../styles/carbon/factorSearchComponent.module.css";
import { useTranslation } from "react-i18next";
import { useRecoilState, useResetRecoilState } from "recoil";
import { carbonSearchState } from "../../store/atom";

const FactorSearchComponent = ({ onSearch }: any) => {
  const { t } = useTranslation();

  const [searchObj, setSearchObj] = useRecoilState<any>(carbonSearchState);

  const initSearchObj = useResetRecoilState(carbonSearchState);
  const onInit = () => {
    initSearchObj();
  };

  const handleSearch = () => {
    onSearch();
  };

  const scopeList = ["direct emissions source", "Indirect emissions source"];
  const activityScopeList = {
    "direct emissions source": [
      "stationary combustion",
      "mobile combustion",
      "electricity",
      "heat/steam",
    ],
    "Indirect emissions source": ["electricity", "steam(GT)", "heat/steam"],
  };

  const activityList = {
    "stationary combustion": ["solid fuel combustion"],
    "steam(GT)": ["external heat(A Type)"],
    "mobile combustion": [
      "mobile combustion(airline)",
      "solid fuel combustion",
    ],
    electricity: [
      "Indirect emissions(external electricity)",
      "mobile combustion(airline)",
      "external heat(A Type)",
    ],
    "heat/steam": [
      "combustion(airline)",
      "Indirect emissions(external electricity)",
    ],
  };

  const sourceList = {
    activity: "source",
    "solid fuel combustion": ["natural gas(liqeur)", "electricity"],
    "mobile combustion(airline)": ["gasoline", "electricity"],
    "Indirect emissions(external electricity)": ["electricity"],
    "external heat(A Type)": ["heat/steam", "electricity"],
  };

  const renderOptions = (arr: any) => {
    return arr.map((value: string) => ({ value, label: value }));
  };

  return (
    <div className={styles.search}>
      <div className={styles.selects}>
        <Select
          style={{ width: 200 }}
          value={searchObj.scope}
          onChange={(value: string) => {
            setSearchObj({ ...searchObj, scope: value });
          }}
          options={renderOptions(scopeList)}
        />
        <Select
          style={{ width: 200 }}
          value={searchObj.activityScope}
          onChange={(value: string) => {
            setSearchObj({ ...searchObj, activityScope: value });
          }}
          options={renderOptions(activityScopeList[searchObj.scope])}
        />
        <Select
          style={{ width: 300 }}
          value={searchObj.activity}
          onChange={(value: string) => {
            setSearchObj({ ...searchObj, activity: value });
          }}
          options={renderOptions(activityList[searchObj.activityScope])}
        />
        <Select
          style={{ width: 200 }}
          value={searchObj.source}
          onChange={(value: string) => {
            setSearchObj({ ...searchObj, source: value });
          }}
          options={renderOptions(sourceList[searchObj.activity])}
        />
        <Select
          style={{ width: 150 }}
          value={searchObj.type}
          onChange={(value: string) => {
            setSearchObj({ ...searchObj, type: value });
          }}
          options={[
            {
              value: "emission",
              label: "emission",
            },
            {
              value: "fever",
              label: "fever",
            },
            {
              value: "oil",
              label: "oil",
            },
          ]}
        />
      </div>
      <div className={styles.buttons}>
        <Button className="initbtn" onClick={onInit}>
          {t("main:shareData:initialization")}
        </Button>
        <Button type="primary"
              className="searchbtn" onClick={() => handleSearch()}>
          {t("main:shareData:search")}
        </Button>
      </div>
    </div>
  );
};

export default FactorSearchComponent;
