import React from "react";

const CsuSvg = ({
  statusColor,
  titleColor,
  data,
  setIsEqpChart,
  setEqpName,
}) => {
  const showModal = () => {
    setIsEqpChart(true);
    setEqpName(data.eqpName);
  };

  return (
    <div
      onClick={() => {
        showModal();
      }}
    >
      <svg
        id="_\uB808\uC774\uC5B4_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="40px"
        height="43px"
        viewBox="0 0 60 43"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`\n\t.csu${titleColor}{fill:${statusColor};stroke:${statusColor};stroke-width:0.5;}\n`}
        </style>
        <g id="_\uADF8\uB8F9_1197-5">
          <g id="_\uADF8\uB8F9_563-5">
            <path
              id="_\uD328\uC2A4_569-5"
              className={`csu${titleColor}`}
              d="M33.8,45.2H14.4c-0.9,0-1.6-0.7-1.5-1.6v-9.1h7.2v-0.1h-7.2v-0.8h8.2v2L18.4,36v6.3l1,0.6 h9.4l1.2-0.7v-6.2l-3-0.4v-2.1h8.2v0.8h-7.3l0,0h7.2v9.1C35.1,44.2,34.8,45.2,33.8,45.2L33.8,45.2L33.8,45.2z M13.3,35v8.6 c0,0.6,0.5,1.2,1.1,1.2h19.3c0.9,0,1-1.2,1-1.2V35h-7.2v-0.8h-0.1v1.3l3,0.4v6.8L29,43.5h-9.7l-1.4-0.8v-6.9l2.7-0.3v-1.3h-0.1V35 C20.6,35,13.3,35,13.3,35z"
            />
            <path
              id="_\uD328\uC2A4_570-5"
              className={`csu${titleColor}`}
              d="M35.9,42.5h-0.4v-8.8h21.2v0.4H36L35.9,42.5L35.9,42.5z"
            />
            <path
              id="_\uD328\uC2A4_571-5"
              className={`csu${titleColor}`}
              d="M35.9,37.8h-1.2v-2.3h1.2V37.8z M35.2,37.4h0.3v-1.5h-0.3V37.4z"
            />
          </g>
          <path
            id="_\uD328\uC2A4_572-5"
            className={`csu${titleColor}`}
            d="M25.8,31.5h-1.9v-0.9h1.9V31.5z M24.4,31h1l0,0H24.4L24.4,31L24.4,31z"
          />
          <path
            id="_\uD328\uC2A4_573-5"
            className={`csu${titleColor}`}
            d="M25.7,31h-1.5V21h1.5V31z M24.6,30.5h0.7v-9.2h-0.7V30.5z"
          />
          <path
            id="_\uD328\uC2A4_574-5"
            className={`csu${titleColor}`}
            d="M43.5,21.4H22.7v-0.9h21.2L43.5,21.4L43.5,21.4L43.5,21.4z M23.1,21h20.1v-0.1L23.1,21 L23.1,21z"
          />
          <path
            id="_\uD328\uC2A4_575-5"
            className={`csu${titleColor}`}
            d="M26.2,20.9h-2.6v-3.2h2.6V20.9z M24.1,20.5h1.7V18h-1.7V20.5z"
          />
          <rect
            id="_\uC0AC\uAC01\uD615_228-5"
            x={23.6}
            y={20.2}
            className={`csu${titleColor}`}
            width={2.6}
            height={0.7}
          />
          <path
            id="_\uD328\uC2A4_576-5"
            className={`csu${titleColor}`}
            d="M46.9,24.5h-5.2l2.7-6.1L46.9,24.5L46.9,24.5z M42.3,24.2h4l-1.9-4.6L42.3,24.2L42.3,24.2z "
          />
          <path
            id="_\uD328\uC2A4_577-5"
            className={`csu${titleColor}`}
            d="M45.7,21.9h-2.9l0.6-1.3h1.8L45.7,21.9z M43.5,21.5h1.6L44.9,21h-1.2L43.5,21.5z"
          />
          <path
            id="_\uD328\uC2A4_578-5"
            className={`csu${titleColor}`}
            d="M48,25h-7.4v-0.9H48C48,24.2,48,25,48,25z M41,24.7h6.5v-0.1L41,24.7L41,24.7z"
          />
          <path
            id="_\uD328\uC2A4_579-5"
            className={`csu${titleColor}`}
            d="M48,25.5h-7.4v-0.9H48C48,24.7,48,25.5,48,25.5z M41,25.2h6.5v-0.1L41,25.2L41,25.2z"
          />
          <path
            id="_\uD328\uC2A4_580-5"
            className={`csu${titleColor}`}
            d="M44.6,20.2L44.1,19l10.5-0.5v1.1L44.6,20.2z M44.7,19.2l0.2,0.4l9.3-0.5v-0.3L44.7,19.2 L44.7,19.2L44.7,19.2z"
          />
          <path
            id="_\uD328\uC2A4_581-5"
            className={`csu${titleColor}`}
            d="M57.1,20.2h-3v-2.4h3V20.2z M54.6,19.8h2.1v-1.6h-2.1V19.8z"
          />
          <path
            id="_\uD328\uC2A4_641-5"
            className={`csu${titleColor}`}
            d="M41.5,21.2h0.4l0.3,2.9h-0.4L41.5,21.2z"
          />
          <path
            id="_\uD328\uC2A4_582-5"
            className={`csu${titleColor}`}
            d="M44.4,19.2H26.1v-0.4l19.2-4h0.1l8.9,3.6l-0.1,0.4L44.4,19.2L44.4,19.2L44.4,19.2z  M28.1,18.9h16.2l8.9-0.3l-8-3.2L28.1,18.9L28.1,18.9z"
          />
          <path
            id="_\uD328\uC2A4_583-5"
            className={`csu${titleColor}`}
            d="M42.1,19.2L41.8,19l3.6-4.2l2.2,4.2l-0.4,0.2l-2-3.7L42.1,19.2z"
          />
          <path
            id="_\uD328\uC2A4_640-5"
            className={`csu${titleColor}`}
            d="M44.1,19l0.9-4l0.4,0.1l-0.9,4L44.1,19z"
          />
          <path
            id="_\uD328\uC2A4_584-5"
            className={`csu${titleColor}`}
            d="M51.3,34.2h-1.1l-0.7-7.3H39.1l-0.7,7.3h-1v-8.9h13.9V34.2L51.3,34.2z M50.6,33.8h0.3v-8.1 H37.8v8.1H38l0.7-7.3h11.2L50.6,33.8L50.6,33.8z"
          />
          <path
            id="_\uD328\uC2A4_585-5"
            className={`csu${titleColor}`}
            d="M22.8,33.2h-0.2v-0.8h1.9v-0.1l0,0v-1.2h1v1.5c0,0.5-0.6,0.7-0.9,0.7L22.8,33.2L22.8,33.2z  M24.9,32.2c0,0.2,0,0.3-0.1,0.5c0,0-0.1,0.1-0.2,0.1c0.1,0,0.4-0.1,0.4-0.2v-1.2h-0.1V32.2L24.9,32.2L24.9,32.2z"
          />
          <rect
            id="_\uC0AC\uAC01\uD615_231-5"
            x={24.4}
            y={31.5}
            className={`csu${titleColor}`}
            width={0.2}
            height={0.4}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_232-5"
            x={25.2}
            y={31.5}
            className={`csu${titleColor}`}
            width={0.2}
            height={0.4}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_233-5"
            x={24.4}
            y={32}
            className={`csu${titleColor}`}
            width={0.2}
            height={0.4}
          />
          <path
            id="_\uD328\uC2A4_636-5"
            className={`csu${titleColor}`}
            d="M24.9,33l0.4-0.2l0.1,0.2L25,33.2L24.9,33z"
          />
          <path
            id="_\uD328\uC2A4_637-5"
            className={`csu${titleColor}`}
            d="M22.4,32.5l0.4-0.2l0.1,0.1l-0.4,0.2L22.4,32.5z"
          />
          <path
            id="_\uD328\uC2A4_638-5"
            className={`csu${titleColor}`}
            d="M22.4,33l0.1-0.2l0.3,0.3l-0.1,0.2L22.4,33z"
          />
          <rect
            id="_\uC0AC\uAC01\uD615_237-5"
            x={22.5}
            y={32.7}
            className={`csu${titleColor}`}
            width={0.2}
            height={0.4}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_238-5"
            x={23}
            y={32.4}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.2}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_239-5"
            x={23}
            y={33.2}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.2}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_240-5"
            x={23.5}
            y={32.4}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.2}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_241-5"
            x={23.5}
            y={33.2}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.2}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_242-5"
            x={24}
            y={32.4}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.2}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_243-5"
            x={24}
            y={33.2}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.2}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_244-5"
            x={24.4}
            y={33}
            className={`csu${titleColor}`}
            width={0.4}
            height={0.3}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_245-5"
            x={25.2}
            y={32}
            className={`csu${titleColor}`}
            width={0.2}
            height={0.4}
          />
          <rect
            id="_\uC0AC\uAC01\uD615_246-5"
            x={25.2}
            y={32.4}
            className={`csu${titleColor}`}
            width={0.2}
            height={0.4}
          />
        </g>
      </svg>
    </div>
  );
};
export default CsuSvg;
