import React from "react";

const StackerSvg = ({
  statusColor,
  titleColor,
  data,
  setIsEqpChart,
  setEqpName,
}) => {
  const showModal = () => {
    setIsEqpChart(true);
    setEqpName(data.eqpName);
  };

  return (
    data && (
      <div
        onClick={() => {
          showModal();
        }}
      >
        <svg
          id="_\uB808\uC774\uC5B4_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="40px"
          // height="60px"
          // viewBox="8 0 50 60"
          idth="40px"
          height="43px"
          viewBox="0 0 50 55"
          xmlSpace="preserve"
          // {...props}
        >
          <style type="text/css">
            {`\n\t.st${titleColor}{fill:none;stroke:${statusColor};stroke-linejoin:bevel;}\n\t.st1${titleColor}{fill:none;stroke:${statusColor};stroke-miterlimit:10;}\n`}
          </style>
          <g id="_\uADF8\uB8F9_184-11">
            <g id="_\uADF8\uB8F9_182-11">
              <path
                id="_\uD328\uC2A4_479-11"
                className={`st${titleColor}`}
                d="M33.1,31.1h-6.7l3.5-7.8L33.1,31.1h-6.7"
              />
              <path
                id="_\uD328\uC2A4_480-11"
                className={`st${titleColor}`}
                d="M30.8,25.8h-2.2L28,27.1h3.3L30.8,25.8L30.8,25.8z"
              />
            </g>
            <path
              id="_\uD328\uC2A4_481-11"
              className={`st1${titleColor}`}
              d="M15,22.9L15,22.9v1l14.2,0.8l0.5-1.2L15,22.9L15,22.9L15,22.9z"
            />
            <rect
              id="_\uC0AC\uAC01\uD615_99-11"
              x={11.3}
              y={21.8}
              className={`st1${titleColor}`}
              width={3.7}
              height={2.9}
            />
            <g id="_\uADF8\uB8F9_183-11">
              <path
                id="_\uD328\uC2A4_482-11"
                className={`st${titleColor}`}
                d="M15,22.7l13-5.3l28,5.8H29.4L15,22.7L15,22.7z"
              />
              <path
                id="_\uD328\uC2A4_483-11"
                className={`st1${titleColor}`}
                d="M33,23.2l-4.9-5.8l-3,5.7"
              />
              <line
                id="_\uC120_100-11"
                className={`st1${titleColor}`}
                x1={28.1}
                y1={17.4}
                x2={29.4}
                y2={23.2}
              />
            </g>
            <rect
              id="_\uC0AC\uAC01\uD615_100-11"
              x={20.2}
              y={41.3}
              className={`st1${titleColor}`}
              width={0.4}
              height={1.3}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_101-11"
              x={39}
              y={41.3}
              className={`st1${titleColor}`}
              width={0.4}
              height={1.3}
            />
            <path
              id="_\uD328\uC2A4_484-11"
              className={`st1${titleColor}`}
              d="M20.8,41.3L20.8,41.3l1.1-7h15.7l1.2,6.9h0.7v-8.7H20l-0.2,8.8H20.8L20.8,41.3z"
            />
            <rect
              id="_\uC0AC\uAC01\uD615_102-11"
              x={24.7}
              y={31.1}
              className={`st1${titleColor}`}
              width={10.2}
              height={0.8}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_103-11"
              x={24.7}
              y={31.9}
              className={`st1${titleColor}`}
              width={10.2}
              height={0.7}
            />
            <path
              id="_\uD328\uC2A4_485-11"
              className={`st${titleColor}`}
              d="M56.5,24.2L56.5,24.2c0,0.5,0.5,1,1,1l0,0h-27l-0.7-1.9l27.7-0.1 C57,23.2,56.6,23.7,56.5,24.2L56.5,24.2L56.5,24.2z"
            />
            <path
              id="_\uD328\uC2A4_486-11"
              className={`st${titleColor}`}
              d="M57.8,23.2c-0.1,0-0.1,0-0.2,0H57.8z"
            />
            <path
              id="_\uD328\uC2A4_489-11"
              className={`st${titleColor}`}
              d="M57.8,23.2L57.8,23.2c0.5,0.1,0.8,0.5,0.9,1c0,0.5-0.4,0.9-0.8,1c-0.1,0-0.1,0-0.2,0l0,0 c-0.5,0-1-0.4-1.1-0.9c0-0.5,0.4-1,0.9-1h0.1l0,0C57.7,23.2,57.8,23.2,57.8,23.2L57.8,23.2L57.8,23.2z"
            />
            <line
              id="_\uC120_101-11"
              className={`st${titleColor}`}
              x1={33.4}
              y1={25.2}
              x2={33}
              y2={30.9}
            />
          </g>
        </svg>
      </div>
    )
  );
};
export default StackerSvg;
