import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const LineBasicChart = ({ title, data, width, height, category }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);
  const [colorList, setColorList] = useState<any>([
    "#2BFFF8",
    "#0075CF",
    "#5FE8BA",
    "#ff0000",
    "#2BFFF8",
    "#0075CF",
    "#5FE8BA",
    "#ff0000",
    "#E4E4E4",
  ]);
  const chartOptions: ApexOptions = {
    title: {
      text: title,
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "normal",
        fontFamily: "Noto Sans KR",
        color: "#F0F0F0",
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Noto Sans KR",
      },
      // onDatasetHover: {},
      x: {
        formatter: function (val) {
          return val + " hour";
        },
      },
      y: {
        // formatter: undefined,
        formatter: function (val) {
          return val + "KWh";
        },
        // title: {
        //   formatter: (series) => series + "hour",
        // },
      },
      //   z: {
      //     formatter: undefined,
      //     title: 'Size: ',
      //   },
      marker: {
        show: true,
      },
      items: {
        // display: 'flex',
      },
      // fixed: {
      //   enabled: false,
      //   position: "topRight",
      //   offsetX: 0,
      //   offsetY: 0,
      // },
    },
    // tooltip: {
    //   x: {
    //     formatter: function (val) {
    //       return val + 'K';
    //     },
    //   },
    //   y: {
    //     formatter: function (val) {
    //       return val + 'K';
    //     },
    //   },
    // },
    chart: {
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      // curve: ['smooth', 'straight', 'stepline']
      // lineCap: "butt",
      width: 2,
    },

    xaxis: {
      categories: category,
      labels: {
        style: {
          colors: "#F0F0F0",
        },
      },
    },
    yaxis: {
      // labels: {
      //   formatter: function (value) {
      //     const val: string = value.toLocaleString();
      //     return val;
      //   },
      // },

      labels: {
        style: {
          colors: "#F0F0F0",
        },
      },
    },
    legend: {
      show: true,
      labels: {
        colors: "#F0F0F0",
      },
    },
    // colors: [
    //   "#2BFFF8",
    //   "#0075CF",
    //   "#5FE8BA",
    //   "#ff0000",
    //   "#2BFFF8",
    //   "#0075CF",
    //   "#5FE8BA",
    //   "#ff0000",
    //   "#E4E4E4",
    // ],
    colors: colorList,
  };

  useEffect(() => {
    setSeries(data);
    if (data.length === 3) {
      setColorList(["#2BFFF8", "#0075CF", "#E4E4E4"]);
    }
  }, []);

  return (
    series && (
      <Chart
        options={chartOptions}
        type="line"
        series={series}
        width={width}
        height={height}
      />
    )
  );
};

export default LineBasicChart;
