import React from "react";
import EqpCard from "./EqpCard";
import styles from "@styles/optFac/optimumFacilityDash.module.css";

const EqpList = ({ type, eqps, openFlowModal, openCycleModal }: any) => {
  return (
    <>
      <div className={styles.eqpList}>
        {eqps.map((eqp: any) => (
          <EqpCard
            visible={eqp.visible}
            eqp={eqp.value}
            openFlowModal={openFlowModal}
            openCycleModal={openCycleModal}
          />
        ))}
      </div>
    </>
  );
};

export default EqpList;
