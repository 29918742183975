import React, { useEffect, useRef, useState } from "react";
import ECharts from "echarts-for-react";
import dayjs from "dayjs";
import moment from "moment";
import {
  HomeFilled,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import styles from "@styles/optFac/divisionEChart.module.css";
import service from "@services/OptFacService";

const DivisionEChart = (props: any) => {
  const { title, tag, type, displayCount } = props;
  const [count, setCount] = useState<number>(0);
  const [dataStatus, setDataStatus] = useState<Boolean>(null);
  const chartRef = useRef(null);
  const [zoomStartPos, setZoomStartPos] = useState(null);
  const [zoomEndPos, setZoomEndPos] = useState(null);
  // const [isZooming, setIsZooming] = useState(false);

  const dateList = useRef<any>([]); // category 저장용
  const [option, setOption] = useState(null);

  useEffect(() => {
    if (props) {
      getOption(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getOption = (param: any) => {
    const category = getXaxis(param.data.isSummary, param.data.list);
    dateList.current = category;

    let status = param.data.isSummary ? false : true;
    setDataStatus(status);
    const newOption: any = {
      grid: {
        left: 60,
        top: 30,
        right: 50,
        bottom: 30,
      },
      //   toolbox: {
      //     show: true,
      //     dataZoom: {
      //       yAxisIndex: "none",
      //     },
      //   },
      tooltip: {
        show: param.data.isSummary ? false : true,
        trigger: "axis",
        formatter(params: any) {
          let tooltipString = [];
          tooltipString.push(
            moment
              .unix(params[0].axisValue / 1000)
              .format("YYYY-MM-DD HH:mm:ss")
          );
          params.forEach((param: any) => {
            tooltipString.push(
              `${param.marker} ${param.seriesName} : ${param.value}`
            );
          });
          return tooltipString.join("<br/>");
        },
      },
      xAxis: {
        data: category,
        axisLabel: {
          formatter: (value: any) => {
            const date = moment
              .unix(value / 1000)
              .format("YYYY-MM-DD HH:mm:ss");
            return date;
          },
        },
      },
      yAxis: {},
      series: [
        {
          name: title,
          data: getSeries(param.data.list),
          type: "line",
        },
      ],
    };
    setOption(newOption);
  };

  const getSeries = (values: any) => {
    let data: any = [];
    setCount(values.length);
    for (let d of values) {
      data.push(d?.value || 0);
    }
    return data;
  };

  const getXaxis = (isSummary: any, data: any) => {
    let xaxis: any = [];
    for (let d of data) {
      if (isSummary === true) {
        xaxis.push(d.date);
      } else {
        xaxis.push(dayjs(d.date).valueOf());
      }
    }
    return xaxis;
  };

  function onZoomReset() {
    getOption(props);
    setZoomStartPos(null);
    setZoomEndPos(null);
    // setIsZooming(false);
  }

  function renderZoomBox() {
    if (!zoomStartPos || !zoomEndPos) return null;
    const chart = chartRef.current.getEchartsInstance();
    const [startX] = zoomStartPos;
    const [endX] = zoomEndPos;
    const left = Math.min(startX, endX);
    const width = Math.abs(endX - startX);
    const top = chart.convertToPixel("grid", [
      0,
      chart.getOption().yAxis.max,
    ])[1];
    const height = chart.getHeight();
    return (
      <div className={styles.chart_drag_container}>
        <div className={styles.chart_drag_mask} />
        <div
          className={styles.chart_drag_box}
          style={{ left, top, width, height }}
        />
      </div>
    );
  }

  function onChartMouseDown(e: any) {
    const { clientX, target } = e;
    const { left } = target.getBoundingClientRect();
    const offsetX = clientX - left;
    chartRef.current && setZoomStartPos([offsetX, e.screenY]);
    chartRef.current && setZoomEndPos([offsetX, e.screenY]);
    // setIsZooming(true);
  }

  function onChartMouseMove(e: any) {
    e.stopPropagation();
    if (zoomStartPos) {
      const { clientX, target } = e;
      const { left } = target.getBoundingClientRect();
      const offsetX = clientX - left;
      setZoomEndPos([offsetX, e.screenY]);
    }
  }

  const [stepBackList, setStepBackList] = useState<any>([]);
  const [stepForwardList, setStepForwardList] = useState<any>([]);

  const onChartMouseUp = async (e: any) => {
    if (zoomStartPos && zoomEndPos && chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      const x1 = chart.convertFromPixel("grid", zoomStartPos)[0];
      const x2 = chart.convertFromPixel("grid", zoomEndPos)[0];
      const [minX, maxX] = [Math.min(x1, x2), Math.max(x1, x2)];
      const xRange = chart.getOption().xAxis[0].data.slice(minX, maxX + 1);
      //   const yRange = chart.getOption().series[0].data.slice(minX, maxX + 1);

      const from = xRange[0];
      let to = xRange[xRange.length - 1];

      if (to - from < 60000) {
        to = from + 60000;
      }

      setStepBackList([...stepBackList, { from, to }]);

      const param = {
        date: {
          startT: dayjs(from).valueOf(),
          endT: dayjs(to).valueOf(),
          start: dayjs(from).format("YYYY-MM-DD HH:mm:ss.SSS"),
          end: dayjs(to).format("YYYY-MM-DD HH:mm:ss.SSS"),
        },
        tagList: [tag],
        type,
        displayCount,
      };
      // console.log("param >>> ", param);
      //   spinStatus(true);
      const newOptions = await getTagTrendChart(param);

      //   spinStatus(false);
      getOption(newOptions);
      setZoomStartPos(null);
      setZoomEndPos(null);
    }
  };

  const getTagTrendChart = async (param: any) => {
    let result;
    await service.getTagTrend(param).then((res) => {
      const tagList = res.data;

      let chartData: any = [];
      Object.keys(tagList).map((name) => {
        return chartData.push({ title: name, data: tagList[name] });
      });
      dateList.current = [];
      result = chartData[0];
    });
    return result;
  };

  const onStepBack = async () => {
    if (stepBackList.length === 0) {
      onZoomReset();
      return;
    }

    if (stepForwardList.length === 0) {
      const first = stepBackList.pop();
      setStepBackList([first]);
    }

    const pop = stepBackList.pop();

    setStepBackList(stepBackList);
    setStepForwardList([...stepForwardList, pop]);
    // console.log("back >> ", pop);

    const param = {
      date: {
        startT: dayjs(pop.from).valueOf(),
        endT: dayjs(pop.to).valueOf(),
        start: dayjs(pop.from).format("YYYY-MM-DD HH:mm:ss.SSS"),
        end: dayjs(pop.to).format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      tagList: [tag],
      type,
      displayCount,
    };
    // console.log("param >>> ", param);
    //   spinStatus(true);
    const newOptions = await getTagTrendChart(param);

    //   spinStatus(false);
    getOption(newOptions);
    setZoomStartPos(null);
    setZoomEndPos(null);
  };

  const onStepForward = async () => {
    if (stepForwardList.length === 0) return;

    const pop = stepForwardList.pop();
    setStepForwardList(stepForwardList);
    setStepBackList([...stepBackList, pop]);
    // console.log("stepForwardList.length", stepForwardList.length);
    // console.log("forward >> ", pop);

    const param = {
      date: {
        startT: dayjs(pop.from).valueOf(),
        endT: dayjs(pop.to).valueOf(),
        start: dayjs(pop.from).format("YYYY-MM-DD HH:mm:ss.SSS"),
        end: dayjs(pop.to).format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      tagList: [tag],
      type,
      displayCount,
    };
    // console.log("param >>> ", param);
    //   spinStatus(true);
    const newOptions = await getTagTrendChart(param);

    //   spinStatus(false);
    getOption(newOptions);
    setZoomStartPos(null);
    setZoomEndPos(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div
          className={styles.title}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div>
            TAG : {title.split("／").pop()}({title})
          </div>
          {dataStatus !== null && (
            <>
              <div style={{ color: dataStatus ? "green" : "red" }}>
                STATUS : {dataStatus ? "Raw Data" : "Summary Data"}
              </div>
              <div>COUNT : {count.toLocaleString()}</div>
            </>
          )}
        </div>

        <div style={{ display: "flex" }}>
          <div className={styles.step} onClick={onStepBack}>
            <StepBackwardOutlined />
          </div>
          <div className={styles.step} onClick={onStepForward}>
            <StepForwardOutlined />
          </div>
          <div className={styles.home} onClick={onZoomReset}>
            <HomeFilled />
          </div>
        </div>
      </div>
      <div
        className={styles.chart}
        onMouseDown={onChartMouseDown}
        onMouseMove={onChartMouseMove}
        onMouseUp={onChartMouseUp}
      >
        {renderZoomBox()}
        {option && (
          <ECharts
            ref={chartRef}
            option={option}
            notMerge={true}
            lazyUpdate={true}
          />
        )}
      </div>
    </div>
  );
};

export default DivisionEChart;
