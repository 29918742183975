import React from "react";
import styles from "@styles/optFac/optimumFacilityDash.module.css";
import { Dropdown, Menu } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const EqpCard = ({ visible, eqp, openFlowModal, openCycleModal }: any) => {
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
            // onClick={() => {
            //   // setIsStatusModal(true);
            // }}
            >
              상태 수정
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                // setIsWorstRankModal(true);
              }}
            >
              후 순위
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                openCycleModal(true);
              }}
            >
              정비 주기
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              onClick={() => {
                openFlowModal(true);
              }}
            >
              공정변수 근거
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <div className={styles.eqpCard}>
      {visible && (
        <>
          <div className={styles.eqpCardtitle}>{eqp.name}</div>
          <div className={styles.svgContainer}>
            <CheckCircleOutlined className={styles.worstRank} />
            <ExclamationCircleOutlined className={styles.cycle} />
            <Dropdown overlay={menu}>
              {/* status : 상태 */}
              {/* rank : 순위 */}
              {/* worstRank : 후 순위 */}
              {/* cycle : 정비주기 */}
              <div />
            </Dropdown>
          </div>
          <div className={styles.eqpCardValue}>{eqp.value}Kwh</div>
        </>
      )}
    </div>
  );
};

export default EqpCard;
