import React, { useState } from "react";
import dayjs from "dayjs";

import { Select, DatePicker, Radio } from "antd";
const { RangePicker } = DatePicker;

const DatePickerAndPeriod = (props: any) => {
  const { setDate } = props;
  const [dataRadio, setDataRadio] = useState("RANGE");
  const [period, setPeriod] = useState();

  const DatePickerAndPeriodDataRadioType = {
    RANGE: "RANGE",
    PERIOD: "PERIOD",
  };

  const onDateChange = (value: any) => {
    const from = value[0];
    const to = value[1];
    setDate({
      from,
      to,
    });
  };

  const periodChange = (_: any, option: any) => {
    setPeriod(option["label"]);
    const number = option["value"].substring(0, 1);
    const gap = option["value"].substring(1);
    const from = dayjs().subtract(number, gap);
    const to = dayjs();

    setDate({
      from,
      to,
    });
  };

  const isDisableTarget = (target: any) => {
    return dataRadio !== target;
  };

  const onPrevent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Radio.Group
      value={dataRadio}
      onChange={(e) => setDataRadio(e.target.value)}
    >
      <Radio value={DatePickerAndPeriodDataRadioType.RANGE}>
        <RangePicker
          format={"YYYY-MM-DD HH:mm:ss"}
          showTime
          disabled={isDisableTarget(DatePickerAndPeriodDataRadioType.RANGE)}
          onChange={onDateChange}
        />
      </Radio>
      <Radio value={DatePickerAndPeriodDataRadioType.PERIOD}>
        <span onClick={onPrevent}>
          <Select
            disabled={isDisableTarget(DatePickerAndPeriodDataRadioType.PERIOD)}
            value={period}
            placeholder="Select Period"
            style={{ width: 180, marginRight: "10px" }}
            onChange={periodChange}
            options={[
              { value: "1hour", label: "1hours" },
              { value: "2hour", label: "2hours" },
              { value: "3hour", label: "3hours" },
              { value: "1day", label: "1days" },
              { value: "1week", label: "1weeks" },
              { value: "1month", label: "1months" },
            ]}
          />
        </span>
      </Radio>
    </Radio.Group>
  );
};

export default DatePickerAndPeriod;
