/**
 * These dependencies will be made available to the Remote Components.
 */
module.exports = {
  resolve: {
    react: require("react"),
    antd: require("antd"),
    "@ant-design/icons": require("@ant-design/icons"),
    axios: require("axios"),
  },
};
