import React, { useEffect, useRef, useState } from "react";
import ECharts from "echarts-for-react";
import dayjs from "dayjs";
import moment from "moment";
import { HomeFilled } from "@ant-design/icons";
import service from "@services/OptFacService";
import styles from "@styles/optFac/divisionEChart.module.css";

const IntegratedNormalizeEChart = (props: any) => {
  const { tagList, type, displayCount } = props;

  const chartRef = useRef(null);
  const [zoomStartPos, setZoomStartPos] = useState(null);
  const [zoomEndPos, setZoomEndPos] = useState(null);
  const [dataStatus, setDataStatus] = useState<Boolean>(false);
  const dateList = useRef<any>([]); // category 저장용
  const [option, setOption] = useState(null);

  useEffect(() => {
    if (props) {
      getOption(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getOption = (param: any) => {
    const series = getSeries(param.data);
    const legendList = getLegend(param.data);
    const checkIsSummary = getCheckIsSummary(param.data);
    setDataStatus(checkIsSummary);
    const newOption: any = {
      grid: {
        left: 60,
        top: 30,
        right: 50,
        bottom: 30,
      },
      tooltip: {
        show: checkIsSummary,
        trigger: "axis",
        formatter(params: any) {
          let tooltipString = [];
          tooltipString.push(
            moment(Number(params[0].axisValue)).format("YYYY-MM-DD HH:mm")
          );
          params.forEach((param: any) => {
            tooltipString.push(
              `${param.marker} ${param.seriesName} : ${param.value[1]}`
            );
          });
          return tooltipString.join("<br/>");
        },
      },
      legend: {
        data: legendList,
      },
      xAxis: {
        // data: ["1", "2", "3", "4"],
        // data: categor,y,
        min: dateList.current[0],

        max: dateList.current[dateList.current.length - 1],
        date: dateList.current,
        axisLabel: {
          formatter: (value: any) => {
            const date = moment
              .unix(value / 1000)
              .format("YYYY-MM-DD HH:mm:ss");
            return date;
          },
        },
      },

      yAxis: {},
      series,
    };
    setOption(newOption);
  };

  const getCheckIsSummary = (datas: any) => {
    let check = true;
    for (let data of datas) {
      check = !data.data.isSummary && check;
    }

    return check;
  };

  const getLegend = (datas: any) => {
    let category = [];
    for (let data of datas) {
      category.push(data.title);
    }
    return category;
  };
  //   tagList: any
  const getSeries = (tagList: any) => {
    let series: any = [];
    let _dataList: any = [];

    for (let tag of tagList) {
      let values: any = [];
      for (let d of tag.data.list) {
        values.push(d.value);

        // 날짜 관련
        if (!_dataList.includes(d.date)) {
          if (tag.data.isSummary === true) {
            _dataList.push(d.date);
          } else {
            _dataList.push(dayjs(d.date).valueOf());
          }
        }
      }

      _dataList = _dataList.sort((a: any, b: any) => {
        return a - b;
      });
      dateList.current = _dataList;

      if (values.length !== 0) {
        const max: number = values.reduce((pre: number, cur: number) => {
          return Number(pre) > Number(cur) ? pre : cur;
        });
        const min: number = values.reduce((pre: number, cur: number) => {
          return Number(pre) > Number(cur) ? cur : pre;
        });
        let temp: any = [];
        for (let d of tag.data.list) {
          const x = (d.value - min) / (max - min) || 0;

          if (tag.data.isSummary === true) {
            temp.push([d.date, x]);
          } else {
            temp.push([dayjs(d.date).valueOf(), x]);
          }
        }
        series.push({ name: tag.title, type: "line", data: temp });
      } else {
        series.push({ name: tag.title, type: "line", data: [] });
      }
    }
    return series;
    //   series.push({ name: tag.title, type: "line", data: temp });
  };

  function onZoomReset() {
    getOption(props);
    setZoomStartPos(null);
    setZoomEndPos(null);
  }

  function renderZoomBox() {
    if (!zoomStartPos || !zoomEndPos) return null;
    const chart = chartRef.current.getEchartsInstance();
    const [startX] = zoomStartPos;
    const [endX] = zoomEndPos;
    const left = Math.min(startX, endX);
    const width = Math.abs(endX - startX);
    const top = chart.convertToPixel("grid", [
      0,
      chart.getOption().yAxis.max,
    ])[1];
    const height = chart.getHeight();
    return (
      <div className={styles.chart_drag_container}>
        <div className={styles.chart_drag_mask} />
        <div
          className={styles.chart_drag_box}
          style={{ left, top, width, height }}
        />
      </div>
    );
  }

  function onChartMouseDown(e: any) {
    const { clientX, target } = e;
    const { left } = target.getBoundingClientRect();
    const offsetX = clientX - left;
    chartRef.current && setZoomStartPos([offsetX, e.screenY]);
    chartRef.current && setZoomEndPos([offsetX, e.screenY]);
  }

  function onChartMouseMove(e: any) {
    e.stopPropagation();
    if (zoomStartPos) {
      const { clientX, target } = e;
      const { left } = target.getBoundingClientRect();
      const offsetX = clientX - left;
      setZoomEndPos([offsetX, e.screenY]);
    }
  }

  const onChartMouseUp = async (e: any) => {
    if (zoomStartPos && zoomEndPos && chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      const x1 = chart.convertFromPixel("grid", zoomStartPos)[0];
      const x2 = chart.convertFromPixel("grid", zoomEndPos)[0];

      const [minX, maxX] = [Math.min(x1, x2), Math.max(x1, x2)];

      const from = Math.floor(minX);
      let to = Math.floor(maxX);

      if (to - from < 600000) {
        to = from + 600000;
      }

      const param = {
        date: {
          startT: dayjs(from).valueOf(),
          endT: dayjs(to).valueOf(),
          start: dayjs(from).format("YYYY-MM-DD HH:mm:ss.SSS"),
          end: dayjs(to).format("YYYY-MM-DD HH:mm:ss.SSS"),
        },
        tagList,
        type,
        displayCount,
      };
      console.log("param >>> ", param);
      const newOptions = await getTagTrendChart(param);
      getOption(newOptions);
      setZoomStartPos(null);
      setZoomEndPos(null);
    }
  };

  const getTagTrendChart = async (param: any) => {
    let result;
    await service.getTagTrend(param).then((res) => {
      const tagList = res.data;

      let chartData: any = [];
      Object.keys(tagList).map((name) => {
        return chartData.push({ title: name, data: tagList[name] });
      });
      result = {
        data: chartData,
        tagList: tagList,
      };
    });
    return result;
  };

  return (
    <div className={styles.wrapper} style={{ height: "100%" }}>
      <div className={styles.top}>
        <div className={styles.title}>
          {dataStatus !== null && (
            <div style={{ color: dataStatus ? "green" : "red" }}>
              STATUS : {dataStatus ? "Raw Data" : "Summary Data"}
            </div>
          )}
        </div>
        <div className={styles.home} onClick={onZoomReset}>
          <HomeFilled size={23} />
        </div>
      </div>
      <div
        className={styles.chart}
        style={{ height: "90%" }}
        onMouseDown={onChartMouseDown}
        onMouseMove={onChartMouseMove}
        onMouseUp={onChartMouseUp}
      >
        {renderZoomBox()}
        {option && (
          <ECharts
            style={{ height: "100%" }}
            ref={chartRef}
            option={option}
            notMerge={true}
            lazyUpdate={true}
          />
        )}
      </div>
    </div>
  );
};

export default IntegratedNormalizeEChart;
