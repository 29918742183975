import React, { useEffect, useState } from "react";
import styles from "@styles/dataShare/dataDashboard.module.css";
import TextCard from "./TextCard";
import ChartCard from "./ChartCard";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { headerState, authSelector } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import service from "@services/dataShareService";
import MixChart from "./charts/MixChart";
import BubbleChart from "./charts/BubbleChart";
import DonutChart from "./charts/DonutChart";
import data from "../../../data/dataShareInit.json";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import DataDetailModal from "./DataDetailModal";

const MONTH = data["dashboard"]["MONTH"];
const HOURS = data["dashboard"]["HOURS"];
const countObjInit = data["dashboard"]["countObjInit"];
const dataAccStatusInit = data["dashboard"]["dataAccStatusInit"];

const DashBoardDefault = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:shareData"),
      subTitle: t("main:header:dashboard"),
      path: "/shareData/dashboard",
    });
  }, []);

  const { userId } = useRecoilValue<any>(authSelector);
  const [isDataDetail, setIsDataDetail] = useState<boolean>(false);
  const [cWidth, setCWidth] = useState<any>(0);
  const [tHeight, setTHeight] = useState<any>(0);
  const [countObj, setCountObj] = useState<any>(countObjInit);
  // const [dataSize, setDataSize] = useState<any>({ avg: [], thisData: [] });
  // const [dataCount, setDataCount] = useState<any>({ avg: [], thisData: [] });
  const [dataCountDaliy, setDataCountDaliy] = useState<any>();
  const [dataCountWeek, setDataCountWeek] = useState<any>();
  const [dataSharingHour, SetDataSharingHour] = useState<any>({
    avg: [],
    thisData: [],
  });
  const [dataAccStatus, setDataAccStatus] = useState<any>(dataAccStatusInit);
  const [beforeObj, setBeforeObj] = useState<any>({ success: 0, fail: 0 });

  const setModal = () => {
    setIsDataDetail(true);
  };

  const handleOk = () => {
    setIsDataDetail(false);
  };

  const [modalParam, setModalParam] = useState<any>();

  const getDataSharingObjCount = () => {
    const payload = { id: userId };
    service.getDataSharingObjCount(payload).then((res) => {
      setCountObj(res.data);
    });
  };

  // const getDataSize = () => {
  //   service.getDataSize().then((res) => {
  //     const result = res.data;
  //     const avg =
  //       result.lastData.reduce(
  //         (acc: any, cur: any) => Number(acc) + Number(cur)
  //       ) / 12;
  //     let avgList = [];
  //     for (let i of result.lastData) {
  //       avgList.push(avg);
  //     }
  //     setDataSize({ thisData: result.thisData, avg: avgList });
  //   });
  // };

  // const getDataCount = () => {
  //   service.getDataCount().then((res) => {
  //     const result = res.data;
  //     const avg =
  //       result.lastData.reduce(
  //         (acc: any, cur: any) => Number(acc) + Number(cur)
  //       ) / 12;
  //     let avgList = [];
  //     for (let i of result.lastData) {
  //       avgList.push(avg);
  //     }
  //     setDataCount({ thisData: result.thisData, avg: avgList });
  //   });
  // };

  const getDataCountDaliy = () => {
    service.getDataCountDaliy().then((res) => {
      const result = res.data;
      let series = [];
      for (let index in result) {
        const num = Number(index);
        const obj = {
          type: result[index].type,
          datasetName: result[index].datasetName,
        };
        const data = [
          [
            result[index].value + num * 3 * (num % 2),
            result[index].value + num * 4 * (num % 5),
            result[index].value,
          ],
        ];
        series.push({ name: result[index].datasetName, obj, data });
      }

      setDataCountDaliy(series);
    });
  };

  const getDataCounWeek = () => {
    service.getDataCounWeek().then((res) => {
      const result = res.data;
      let series = [];
      for (let index in result) {
        const num = Number(index);
        const obj = {
          type: result[index].type,
          datasetName: result[index].datasetName,
        };
        const data = [
          [
            result[index].value + num * 4 * (num % 5),
            result[index].value + num * 3 * (num % 2),
            result[index].value,
          ],
        ];
        series.push({ name: result[index].datasetName, obj, data });
      }

      setDataCountWeek(series);
    });
  };

  const getDataSharingHour = () => {
    const payload = { id: userId };
    service.getDataSharingHour(payload).then((res) => {
      const result = res.data;
      const avg =
        result.lastData.reduce(
          (acc: any, cur: any) => Number(acc) + Number(cur)
        ) / 24;
      let avgList = [];
      for (let i of result.lastData) {
        avgList.push(avg);
      }
      SetDataSharingHour({ thisData: result.thisData, avg: avgList });
    });
  };

  const getDataAccStatus = () => {
    const payload = { id: userId };
    service.getDataAccStatus(payload).then((res) => {
      const result = res.data;
      setDataAccStatus(result);

      const success =
        result.lastData.success === 0
          ? "-"
          : (
              ((result.thisData.success - result.lastData.success) /
                result.lastData.success) *
              100
            ).toFixed(2) + "%";

      const fail =
        result.lastData.fail === 0
          ? "-"
          : (
              ((result.thisData.fail - result.lastData.fail) /
                result.lastData.fail) *
              100
            ).toFixed(2) + "%";

      setBeforeObj({ success, fail });
    });
  };

  useEffect(() => {
    const tHeight = (window.innerHeight - 400) / 3;
    const cWidth = (window.innerWidth - 300) / 2;
    setCWidth(cWidth);
    setTHeight(tHeight);
  }, [window]);

  useEffect(() => {
    getDataSharingObjCount();
    // getDataSize();
    // getDataCount();
    getDataCountDaliy();
    getDataCounWeek();
    getDataSharingHour();
    getDataAccStatus();
  }, []);

  return (
    <div className={styles.template}>
      <div>
        <TextCard
          title={t("main:shareData:send")}
          interval={t("main:shareData:day")}
          amount={[countObj["sendStdD"], countObj["sendYdaD"]]}
          unit={t("main:shareData:cases")}
          desc={t("main:shareData:pday")}
        />
        <TextCard
          title={t("main:shareData:receive")}
          interval={t("main:shareData:day")}
          amount={[countObj["receiveStdD"], countObj["receiveYdaD"]]}
          unit={t("main:shareData:cases")}
          desc={t("main:shareData:pday")}
        />
        <TextCard
          title={t("main:shareData:send")}
          interval={t("main:shareData:week")}
          amount={[countObj["sendStdW"], countObj["sendYdaW"]]}
          unit={t("main:shareData:cases")}
          desc={t("main:shareData:pweek")}
        />
        <TextCard
          title={t("main:shareData:receive")}
          interval={t("main:shareData:week")}
          amount={[countObj["receiveStdW"], countObj["receiveYdaW"]]}
          unit={t("main:shareData:cases")}
          desc={t("main:shareData:pweek")}
        />
        <TextCard
          title={t("main:shareData:send")}
          interval={t("main:shareData:month")}
          amount={[countObj["sendStdM"], countObj["sendYdaM"]]}
          unit={t("main:shareData:cases")}
          desc={t("main:shareData:pmonth")}
        />
        <TextCard
          title={t("main:shareData:receive")}
          interval={t("main:shareData:month")}
          amount={[countObj["receiveStdM"], countObj["receiveYdaM"]]}
          unit={t("main:shareData:cases")}
          desc={t("main:shareData:pmonth")}
        />
      </div>

      <div style={{ flex: 2 }}>
        <ChartCard
          title={t("main:shareData:chartTitle3")}
          subTitle={t("main:shareData:day")}
          content={
            <BubbleChart
              series={dataCountDaliy}
              width={cWidth}
              height={tHeight * 2.2}
              dateType={"today"}
              setModal={setModal}
              setModalParam={setModalParam}
            />
          }
        />
        <ChartCard
          title={t("main:shareData:chartTitle3")}
          subTitle={t("main:shareData:week")}
          content={
            <BubbleChart
              series={dataCountWeek}
              width={cWidth}
              height={tHeight * 2.2}
              dateType={"week"}
              setModal={setModal}
              setModalParam={setModalParam}
            />
          }
        />
      </div>
      <div>
        <ChartCard
          title={t("main:shareData:chartTitle4")}
          subTitle={t("main:shareData:week")}
          content={
            <MixChart
              width={cWidth}
              height={tHeight}
              label={HOURS}
              data={[
                {
                  name: t("main:shareData:dataCount"),
                  type: "column",
                  data: dataSharingHour.thisData,
                },
                {
                  name: t("main:shareData:previousDayAVG"),
                  type: "line",
                  data: dataSharingHour.avg,
                },
              ]}
            />
          }
        />
        <ChartCard
          title={t("main:shareData:chartTitle5")}
          subTitle={t("main:shareData:day")}
          content={
            <div className={styles.donutDiv}>
              <div>
                <DonutChart
                  label={[
                    t("main:shareData:success"),
                    t("main:shareData:fail"),
                  ]}
                  data={[
                    dataAccStatus.thisData.success,
                    dataAccStatus.thisData.fail,
                  ]}
                  width={tHeight + 80}
                />
              </div>
              <div>
                <div className={styles.title}>{t("main:shareData:pday")}</div>
                <div className={styles.container}>
                  <div className={styles.subTitle}>
                    {t("main:shareData:success")}
                    <div style={{ fontSize: "32px" }}>
                      {dataAccStatus.thisData.success}
                      <span
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        건({beforeObj.success})
                        {dataAccStatus.thisData.success >
                        dataAccStatus.lastData.success ? (
                          <CaretUpOutlined
                            style={{ color: "green", fontSize: "20px" }}
                          />
                        ) : (
                          <CaretDownOutlined
                            style={{ color: "red", fontSize: "20px" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={styles.subTitle}>
                    {t("main:shareData:fail")}
                    <div style={{ fontSize: "32px" }}>
                      {dataAccStatus.thisData.fail}
                      <span
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        {t("main:shareData:cases")} ({beforeObj.fail})
                        {dataAccStatus.thisData.fail >
                        dataAccStatus.lastData.fail ? (
                          <CaretUpOutlined
                            style={{ color: "green", fontSize: "20px" }}
                          />
                        ) : (
                          <CaretDownOutlined
                            style={{ color: "red", fontSize: "20px" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      {isDataDetail && (
        <DataDetailModal
          visible={isDataDetail}
          handleOk={handleOk}
          title={"데이터 공유 이력 상세"}
          //   transactionId={transactionId}
          param={modalParam}
          //   // transactionSubId={transactionSubId}
          //   //   status={""}
        />
      )}
    </div>
  );
};

export default DashBoardDefault;
