import React from "react";
import { RemoteComponent } from "../../../../RemoteComponent";
import { Props } from "@type/props";
import {TreeResData} from "@type/tree";

const TotalModelingTree = (props: any) => {
  const { onChangeData } = props;

  const handleSelect = (data: TreeResData) => {
    if (data === null) return;
    console.log("@@@@ click tag >> ", data);

    if (!data.leaf) return;
    onChangeData(data);
  };

  const treeProps: Props = {
    onSelect: handleSelect,
  };

  return <RemoteComponent {...treeProps} />;
};

export default TotalModelingTree;
