import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import SearchComponent, {
  initSearchValues,
  SearchValues,
} from "../SearchComponent";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "@styles/carbon/variationTrend.module.css";
import LineBasicChart from "./LineBasicChart";
import service from "@services/carbontaxService";

const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 48, color: "gray" }} spin />
);

const VariationTrend = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:variationTrend"),
      path: "/carbonTax/variationTrend",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cWidth, setCWidth] = useState<any>(null);
  const [tHeight, setTHeight] = useState<any>(null);
  const [CO2, setCO2] = useState<any>(null);
  const [N2O, setN2O] = useState<any>(null);
  const [CH4, setCH4] = useState<any>(null);
  const [eleData, setEleData] = useState<any>(null);
  const [, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const createCategory = (length: number) =>
    Array.from({ length }, (_, i) => String(i));

  useEffect(() => {
    const cWidth = window.innerWidth - 350;
    const cHeight = (window.innerHeight - 200) * 0.22;
    setCWidth(cWidth);
    setTHeight(cHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDataAll(initSearchValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAll = (searchValues: SearchValues) => {
    const {
      emission,
      emissionClassification,
      emissionActivity,
      activityData,
      startDate,
      startTime,
      endDate,
      endTime,
    } = searchValues;

    const payload = {
      emission,
      emissionClassification,
      emissionActivity,
      activityData,
      start: startDate + "T" + startTime,
      end: endDate + "T" + endTime,
    };

    service.getCO2TrendByProcess(payload).then((res: any) => {
      setCO2(res.data.result);
      setElectricityChart(res.data.result);
    });
    service.getN2OTrendByProcess(payload).then((res: any) => {
      setN2O(res.data.result);
    });
    service.getCH4TrendByProcess(payload).then((res: any) => {
      setCH4(res.data.result);
    });
  };

  const setElectricityChart = (data: any) => {
    // 전력 = (배출량 / 0.4653) * 1000;
    let totalData = [];
    for (let d of data) {
      const dataList = d["data"];
      let data = [];
      for (let i of dataList) {
        const e = (i / 0.4653) * 1000;
        data.push(e);
      }
      totalData.push({ name: d["name"], data });
    }

    setEleData(totalData);
  };

  const onClickSearch = (searchValues: SearchValues) => {
    getDataAll(searchValues);
  };

  return (
    <div className={styles.templete}>
      <SearchComponent onSearch={onClickSearch} />
      <div className={styles.chartSection}>
        <div>
          {eleData ? (
            <LineBasicChart
              width={cWidth}
              height={tHeight}
              title="Electricity"
              data={eleData}
              category={() => createCategory(eleData.length)}
            />
          ) : (
            loadingIcon
          )}
        </div>
        <div>
          {CO2 ? (
            <LineBasicChart
              width={cWidth}
              height={tHeight}
              title="Carbon Dioxide Emissions"
              data={CO2}
              category={() => createCategory(CO2.length)}
            />
          ) : (
            loadingIcon
          )}
        </div>
        <div>
          {N2O ? (
            <LineBasicChart
              width={cWidth}
              height={tHeight}
              title="Nitrogen Dioxide Emissions"
              data={N2O}
              category={() => createCategory(N2O.length)}
            />
          ) : (
            loadingIcon
          )}
        </div>
        <div>
          {CH4 ? (
            <LineBasicChart
              width={cWidth}
              height={tHeight}
              title="Methane Emissions"
              data={CH4}
              category={() => createCategory(CH4.length)}
            />
          ) : (
            loadingIcon
          )}
        </div>
      </div>
    </div>
  );
};

export default VariationTrend;
