import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

import styles from "@styles/carbon/chartModal.module.css";
import LineBasicChart from "./LineBasicChart";
import service from "@services/carbontaxService";

const ChartModal = ({ isModalOpen, handleOk, processNm }: any) => {
  const [chartCO2, setChartCO2] = useState<any>();
  const [chartCH4, setChartCH4] = useState<any>();
  const [chartN2O, setChartN2O] = useState<any>();
  const [category, setCategory] = useState<any>();

  const setCarbonGasData = () => {
    service.getCarbonGasTrend(processNm).then((res) => {
      const results = res.data.result;
      let _chart = [];
      const length = results[0].data.length;
      let _category = [];

      for (let i = 0; i <= length; i++) {
        _category.push(i);
      }
      setCategory(_category);

      for (let result of results) {
        let name = result.processNm + "-" + result.facNm;
        const dataList = result.data;
        let data = [];
        for (let i in dataList) {
          data.push(dataList[i].toFixed());
        }
        const type = "line";
        const _data = { name, data, type };
        _chart.push(_data);
      }

      let _sumData = [];
      for (let i = 0; i < length; i++) {
        let num = 0;
        for (let j = 0; j < _chart.length; j++) {
          num += Number(_chart[j]["data"][i]);
        }
        _sumData.push(num);
      }
      _chart.push({ name: "sum", type: "area", data: [..._sumData] });
      setChartCO2({ data: [..._chart] });
    });
  };
  const setMethaneGasData = () => {
    service.getMethaneGasTrend(processNm).then((res) => {
      const results = res.data.result;
      let _chart = [];

      const length = results[0].data.length;
      let _category = [];

      for (let i = 0; i <= length; i++) {
        _category.push(i);
      }
      setCategory(_category);

      for (let result of results) {
        let name = result.processNm + "-" + result.facNm;
        const dataList = result.data;
        let data = [];
        for (let i in dataList) {
          data.push(dataList[i].toFixed());
        }
        const type = "line";
        const _data = { name, data, type };
        _chart.push(_data);
      }

      let _sumData = [];
      for (let i = 0; i < length; i++) {
        let num = 0;
        for (let j = 0; j < _chart.length; j++) {
          num += Number(_chart[j]["data"][i]);
        }
        _sumData.push(num);
      }
      _chart.push({ name: "sum", type: "area", data: [..._sumData] });
      setChartCH4({ data: [..._chart] });
    });
  };
  const setNitrogenDioxideData = () => {
    service.getNitrogenDioxideTrend(processNm).then((res) => {
      const results = res.data.result;
      let _chart = [];

      const length = results[0].data.length;
      let _category = [];

      for (let i = 0; i <= length; i++) {
        _category.push(i);
      }
      setCategory(_category);

      for (let result of results) {
        let name = result.processNm + "-" + result.facNm;
        const dataList = result.data;
        let data = [];
        for (let i in dataList) {
          data.push(dataList[i].toFixed());
        }
        const type = "line";
        const _data = { name, data, type };
        _chart.push(_data);
      }

      let _sumData = [];
      for (let i = 0; i < length; i++) {
        let num = 0;
        for (let j = 0; j < _chart.length; j++) {
          num += Number(_chart[j]["data"][i]);
        }
        _sumData.push(num);
      }
      _chart.push({ name: "sum", type: "area", data: [..._sumData] });
      setChartN2O({ data: [..._chart] });
    });
  };

  useEffect(() => {
    setNitrogenDioxideData();
    setCarbonGasData();
    setMethaneGasData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className="charModal"
      closable={false}
      visible={isModalOpen}
      width={812}
      bodyStyle={{
        borderRadius: "10px",
      }}
      footer={null}
    >
      <div className={styles.template}>
        <div className={styles.title}>
          <div>Greenhouse gases Chart</div>
          <div
            className={styles.hover}
            onClick={() => {
              handleOk();
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className={styles.chart}>
          <div>
            {!chartCO2 ? (
              <span className={styles.loading}>
                <LoadingOutlined />
              </span>
            ) : (
              <LineBasicChart
                height={250}
                width={700}
                title={"Carbon dioxide"}
                data={chartCO2.data}
                category={category}
              />
            )}
          </div>
          <div style={{ display: "flex" }}>
            {!chartCH4 ? (
              <span className={styles.loading}>
                <LoadingOutlined />
              </span>
            ) : (
              <LineBasicChart
                height={250}
                width={700}
                title={"Methane gas"}
                data={chartCH4.data}
                category={category}
              />
            )}
          </div>
          <div>
            {!chartN2O ? (
              <span className={styles.loading}>
                <LoadingOutlined />
              </span>
            ) : (
              <LineBasicChart
                height={250}
                width={700}
                title={"Nitrous oxide"}
                data={chartN2O.data}
                category={category}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChartModal;
