import {useEffect} from "react";
import axios, {InternalAxiosRequestConfig} from "axios";
import {tokenDecoder} from "./tokenDecoder";
import {useRecoilState} from "recoil";
import {authSelector} from "@store/atom";
import {AuthService} from "@services/AuthService";

const useSetupAxiosInterceptor = (): void => {
  const [auth, setAuth] = useRecoilState(authSelector);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const accessToken = auth.tokens.accessToken;

      if (accessToken) {
        if (config.url && config.headers) {
          if (
            config.url.indexOf("/api/auth") === -1
          ) {
            config.headers.authorization = "Bearer " + accessToken;
          }
        }
      } else {
        config.headers.accessToken = null;
        config.headers.refreshToken = null;
      }
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async (error) => {
        const {config: originalRequest, response} = error;
        if (response?.status === 401 && originalRequest.url?.indexOf("/api/auth") === -1) {
          if (originalRequest._retry) {
            return Promise.reject(error);
          }
          originalRequest._retry = true;

          const res = await AuthService.refresh();
          const result = tokenDecoder(res?.data);
          const {accessToken: newAccessToken} = res.data;
          setAuth(result);

          originalRequest.headers.authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        }

        return Promise.reject(error);
      }
    );

    // Cleanup the interceptors on unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [auth, setAuth]);
};

export default useSetupAxiosInterceptor;