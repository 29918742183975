/* eslint-disable no-loop-func */
import { useEffect, useState } from "react";
import { Modal, Button, message } from "antd"; // Ant Design 사용 예시
import { CopyOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import styles from "@/styles/dataShare/errorLogHistory.module.css";
import dataShareService from "@/services/dataShareService";

const maxLength = 150;

interface iDatasetDetail {
  dtsetItemUuid: string;
  dataDcmlLenh: string;
  dataLenh: string;
  dataType: string;
  dtsetItemEngNm: string;
  dtsetItemNm: string;
  dtsetItemSeq: string;
  grpSeCd: string;
}

interface iTextSegments {
  key: string;
  tooltip: string;
  length: number;
  dataType: string;
  grpSeCd: string;
}

const ErrorLogFullTextModal = ({
  errorMessage,
  trnsDt,
  text,
}: {
  errorMessage: string;
  trnsDt: string;
  text: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textSegments, setTextSegments] = useState<iTextSegments[]>();

  useEffect(() => {
    getDatasetDetail(text.substring(20, 56));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const getDatasetDetail = (id: string) => {
    dataShareService.getDatasetDetail(id).then((res) => {
      const dataSetDetailList = res.data.list as iDatasetDetail[];
      convertToTextSegments(dataSetDetailList);
    });
  };

  const convertToTextSegments = (dataSetDetails: iDatasetDetail[]) => {
    let segments: iTextSegments[] = [];

    let tempGroupElementsSegments: iTextSegments[] = [];
    let tempSubGroupElementsSegments: iTextSegments[] = [];
    let tempSubSubGroupElementsSegments: iTextSegments[] = [];

    dataSetDetails.sort(
      (a, b) => parseInt(b.dtsetItemSeq) - parseInt(a.dtsetItemSeq)
    ); // 역순으로 정렬해서 하위항목부터 집계

    dataSetDetails.forEach((detail, index) => {
      // GROUP_TYPES에 속하지 않는 경우 직접 추가
      switch (detail.grpSeCd) {
        case "E":
          segments.push({
            key: "E",
            tooltip: detail.dtsetItemNm,
            dataType: detail.dataType,
            length: parseInt(detail.dataLenh),
            grpSeCd: detail.grpSeCd,
          });
          break;
        case "GE":
          tempGroupElementsSegments.push({
            key: "GE",
            tooltip: detail.dtsetItemNm,
            dataType: detail.dataType,
            length: parseInt(detail.dataLenh),
            grpSeCd: detail.grpSeCd,
          });
          break;
        case "SGE":
          tempSubGroupElementsSegments.push({
            key: "SGE",
            tooltip: detail.dtsetItemNm,
            dataType: detail.dataType,
            length: parseInt(detail.dataLenh),
            grpSeCd: detail.grpSeCd,
          });
          break;
        case "SSGE":
          tempSubSubGroupElementsSegments.push({
            key: "SSGE",
            tooltip: detail.dtsetItemNm,
            dataType: detail.dataType,
            length: parseInt(detail.dataLenh),
            grpSeCd: detail.grpSeCd,
          });
          break;

        // 하위 요소 꺼내서 메인 segments 배열에 넣고 초기화
        case "G":
          for (let idx = 0; idx < parseInt(detail.dataLenh); idx++) {
            tempGroupElementsSegments.every((ge) =>
              segments.push({
                ...ge,
                key: "G" + (parseInt(detail.dataLenh) - idx) + "-" + ge.key,
              })
            );
          }
          tempGroupElementsSegments = [];
          break;
        case "SG":
          for (let idx = 0; idx < parseInt(detail.dataLenh); idx++) {
            tempSubGroupElementsSegments.every((sge) =>
              tempGroupElementsSegments.push({
                ...sge,
                key: "SG" + (parseInt(detail.dataLenh) - idx) + "-" + sge.key,
              })
            );
          }
          tempSubGroupElementsSegments = [];
          break;
        case "SSG":
          for (let idx = 0; idx < parseInt(detail.dataLenh); idx++) {
            tempSubSubGroupElementsSegments.every((ssge, index) =>
              tempSubGroupElementsSegments.push({
                ...ssge,
                key: "SSGE" + (parseInt(detail.dataLenh) - idx),
              })
            );
          }
          tempSubSubGroupElementsSegments = [];
          break;

        default:
          break;
      }
    });
    segments.sort((a, b) => -1); // 원래 순서대로 되도록 역순 정렬
    // console.log("segments", segments);
    setTextSegments(segments); // 변환된 segments를 상태에 설정
  };

  const getRandomColorByTitle = (title: string) => {
    // 제목의 해시값을 생성
    const hash = Array.from(title).reduce(
      (acc, char) => acc + char.charCodeAt(0),
      0
    );

    // 해시값을 기반으로 Hue(0 ~ 360) 값을 결정
    const hue = hash % 360;

    // Saturation(채도)와 Lightness(밝기)를 고정된 값으로 설정
    const saturation = 60; // 0% ~ 100% 사이의 적절한 값 선택
    const lightness = 50; // 0% ~ 100% 사이의 적절한 값 선택

    // HSL을 사용하여 색상 생성
    const hslColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return hslColor;
  };

  const ColoredText = () => {
    let offset = 86;
    const components: JSX.Element[] = [];

    // 테이블 헤더
    components.push(
      <thead key="header">
        <tr>
          <th>항목명</th>
          <th>파싱</th>
          <th>그룹 코드</th>
          <th>데이터 타입 (길이)</th>
          <th>내용</th>
        </tr>
      </thead>
    );

    // 테이블 바디
    const rows: JSX.Element[] = textSegments.map((detail, index) => {
      const { key, tooltip, length, dataType, grpSeCd } = detail;
      let color = getRandomColorByTitle(tooltip);
      const content = text.substring(offset, offset + length);
      const parsable = checkParsable(dataType, content);
      const hoverText = tooltip + "[" + grpSeCd + " (" + key + ")]";
      offset += length;

      // 각 데이터에 대한 행 생성
      return (
        <tr
          key={key + (index + 1)}
          style={{
            color: parsable ? color : "White",
            backgroundColor: parsable ? "" : "Red",
            border: "1px solid black",
            padding: "8px",
          }}
        >
          <td title={hoverText}>{tooltip}</td>
          <td title={hoverText}>
            {parsable ? <CheckOutlined /> : <CloseOutlined />}
          </td>
          <td title={hoverText}>
            {grpSeCd} ({key})
          </td>
          <td title={hoverText}>
            {dataType} ({length})
          </td>
          <td title={hoverText}>
            {replaceSpaces(content)}
            <Button
              onClick={() => copyToClipboard(content)}
              style={{ float: "right" }}
            >
              <CopyOutlined /> 복사
            </Button>
          </td>
        </tr>
      );
    });

    components.push(<tbody key="body">{rows}</tbody>);

    return (
      <table
        style={{ width: "100%", border: "1px solid black", padding: "8px" }}
      >
        {components}
      </table>
    );
  };

  const replaceSpaces = (text: string) => {
    return text.replaceAll(" ", "◌̲");
  };

  const checkParsable = (type: string, value: string) => {
    switch (type) {
      case "NUMERIC":
        // 숫자와 공백만 포함되어 있는지 확인하는 정규 표현식
        const isNumeric = /^[\d\s]+(\.\d+)?$/.test(value); // 정수 또는 소수 체크
        return isNumeric;

      case "VARCHAR":
        // VARCHAR는 항상 체크 성공
        return true;

      case "CHAR":
        // CHAR는 항상 체크 성공
        return true;

      default:
        // 지원하지 않는 타입의 경우
        return false;
    }
  };

  const getShortenedText = (str: string) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}…` : str;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const copyToClipboard = (content: string) => {
    try {
      navigator.clipboard.writeText(content).then(() => {
        message.success(
          "텍스트 (length: " + content.length + ")가 클립보드에 복사되었습니다."
        );
      });
    } catch (error) {
      fallbackCopyToClipboard(content);
    }
  };

  const fallbackCopyToClipboard = (content: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = content;
    textArea.style.position = "fixed";
    textArea.style.top = "-9999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        message.success(
          "텍스트 (length: " + content.length + ")가 클립보드에 복사되었습니다."
        );
      } else {
        message.error("클립보드 복사에 실패했습니다.");
      }
    } catch (err) {
      console.error("복사 중 오류가 발생했습니다:", err);
    }

    document.body.removeChild(textArea);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <span onClick={showModal} style={{ cursor: "pointer", color: "blue" }}>
        {getShortenedText(text)}
      </span>

      <Modal
        width={"80vw"}
        height={"60vh"}
        title={
          <div>
            전문 보기 (full: {text.length} / body: {text.length - 86}){" "}
            <span style={{ fontSize: 12 }}>
              {" "}
              송신일자: {dayjs(trnsDt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
            <Button
              onClick={() => copyToClipboard(text)}
              style={{
                marginLeft: "20px",
              }}
            >
              <CopyOutlined />
              전문 클립보드에 복사
            </Button>
          </div>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        open={isModalVisible}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "70vh",
          },
        }} // 스크롤 활성화
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <div style={{ padding: "20px" }}>
          에러 메세지:{" "}
          <Button onClick={() => copyToClipboard(errorMessage)}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={errorMessage}
            />
          </div>
          헤더 header:{" "}
          <Button onClick={() => copyToClipboard(text.substring(0, 86))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(0, 86)}
            />
          </div>
          인터페이스 ID interfaceID(20):
          <Button onClick={() => copyToClipboard(text.substring(0, 20))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(0, 20)}
            />
          </div>
          데이터 셋 UUID dataSetUuid(36):
          <Button onClick={() => copyToClipboard(text.substring(20, 56))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(20, 56)}
            />
          </div>
          유저 아이디 user ID(30):
          <Button onClick={() => copyToClipboard(text.substring(56, 86))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(56, 86)}
            />
          </div>
          <div
            className={styles.errorModalButtonAndTextContainer}
            style={{ flexDirection: "row" }}
          >
            길이 length(5):
            <Button onClick={() => copyToClipboard(text.substring(86, 91))}>
              <CopyOutlined /> 복사
            </Button>
          </div>
          <div>
            DB 항목 길이 합 (
            {textSegments &&
              textSegments.reduce((total, data) => total + data.length, 0)}
            ) - 전문에서 파싱한 길이 ({parseInt(text.substring(86, 91), 10)}) -
            수신한 전문 텍스트 길이 ({text.length - 86})
          </div>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(86, 91)}
            />
          </div>
          전문 fullText:
          <span style={{ width: "20px" }}> </span>
          <Button onClick={() => copyToClipboard(text)}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              style={{ height: "30vh" }}
              readOnly
              value={text}
            />
          </div>
          바디 body (헤더 제외):
          <span style={{ width: "20px" }}> </span>
          <Button
            onClick={() => copyToClipboard(text.substring(86, text.length))}
          >
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              style={{ height: "30vh" }}
              readOnly
              value={text.substring(86, text.length)}
            />
          </div>
          파싱 체크 (참고용):
          <span style={{ width: "20px" }}> </span>
          <div className={styles.errorModalButtonAndTextContainer}>
            {textSegments && ColoredText()}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorLogFullTextModal;
