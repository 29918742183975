import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { headerState, carbonSearchState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import { Table, Button, Modal, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ModifyModal from "./ModifyModal";
import service from "@services/carbontaxService";
import styles from "@styles/carbon/emissionCoefficent.module.css";
import FactorSearchComponent from "../FactorSearchComponent";
import dayjs from "dayjs";

let columnsStart: any = [
  {
    title: "표준",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "버전",
    dataIndex: "version",
    key: "version",
  },
  {
    title: "Tier",
    dataIndex: "tier",
    key: "tier",
  },
  { title: "SCOPE", dataIndex: "scope", key: "scope" },
  {
    title: "배출 활동 구분",
    dataIndex: "activityScope",
    key: "activityScope",
  },
  {
    title: "배출 활동",
    dataIndex: "activity",
    key: "activity",
  },
  {
    title: "활동 자료",
    dataIndex: "source",
    key: "source",
  },
];

let columnsEnd: any = [
  {
    title: "산정 공식",
    dataIndex: "formula",
    key: "formula",
  },
  {
    title: "등록일",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record: any) => {
      if (record === null) return;
      return (
        <div>
          {dayjs(record).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      );
    },
  },
  {
    title: "사용일",
    dataIndex: "usedAt",
    key: "usedAt",
    render: (record: any) => {
      if (record === null) return;
      return (
        <div>
          {dayjs(record).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      );
    },
  },
];

const { confirm } = Modal;

const EmissionCoefficent = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:emissionCoefficent"),
      path: "/carbonTax/emissionCoefficent",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchObj, setSearchObj] = useRecoilState<any>(carbonSearchState);
  const [dataSource, setDataSource] = useState<any>();
  const [selectData, setSelectData] = useState<any>(null);
  const [isModify, setIsModify] = useState<boolean>(false);
  const [fColumn, setFColumn] = useState<any>([]);
  const [factors, setFactors] = useState<any>();

  const setColumnType = () => {
    const type = searchObj.type;
    service.getTypeHeader(type).then((res) => {
      const results = res.data.headers;

      setFactors(results);
      let col = [];
      for (let result of results) {
        const d = { title: result, dataIndex: result, key: result };
        col.push(d);
      }
      setFColumn([...columnsStart, ...col, ...columnsEnd]);
    });
  };

  useEffect(() => {
    setColumnType();
  }, [searchObj.type]);

  const handleCancel = () => {
    setIsModify(false);
  };

  const onCreate = () => {
    setIsModify(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectData(selectedRows[0]);
    },
  };

  const showConfirm = () => {
    confirm({
      title: "이 배출 계수를 정말로 삭제 하시겠습니까?",
      icon: <ExclamationCircleFilled />,
      content: (
        <div className={styles.content}>
          <ul>
            <li>
              <span>표준</span>:<span>{selectData.description}</span>
            </li>
            <li>
              <span>버전</span>:<span>{selectData.version}</span>
            </li>
            <li>
              <span>Tier</span>:<span>{selectData.tier}</span>
            </li>
            <li>
              <span>SCOPE</span>:<span>{selectData.scope}</span>
            </li>
            <li>
              <span>배출 활동 구분</span>:
              <span>{selectData.activityScope}</span>
            </li>
            <li>
              <span>배출 활동</span>:<span>{selectData.activity}</span>
            </li>
            <li>
              <span>활동 자료</span>:<span>{selectData.source}</span>
            </li>
            <li>
              <span>배출 계수 항목</span>
            </li>
            {factors.map((factor: string) => (
              <li>
                <span>{factor}</span>:<span>{selectData[factor]}</span>
              </li>
            ))}
          </ul>
        </div>
      ),
      okText: "예",
      cancelText: "아니오",
      onOk() {
        onDelete(selectData.id);
      },
    });
  };

  const getEmissionFactor = () => {
    service.getEmissionFactor(searchObj).then((res) => {
      const data = res.data.list;
      let _r = [];
      let _d = {};
      for (let i in data) {
        const values = JSON.parse(data[i]["values"]);
        if (values !== null) {
          const keys = Object.keys(values);
          for (let key of keys) {
            _d[key] = values[key];
          }
        }
        const _data = { ...data[i], key: Number(i) + 1, ..._d };
        _r.push(_data);
      }
      setDataSource(_r);
    });
  };

  useEffect(() => {
    getEmissionFactor();
  }, []);

  const onInsert = (payload: any) => {
    service
      .createEmissionFactor(payload)
      .then((res) => {
        const result = res.status;
        if (result === 200) {
          message.success("배출계수가 추가 되었습니다.");
        } else {
          message.error("배출계수가 추가 되지 않았습니다.");
        }
      })
      .catch((error) => {
        message.error("배출계수가 추가 되지 않았습니다.");
      })
      .finally(() => {
        setIsModify(false);
        getEmissionFactor();
      });
  };

  const onUpdate = (payload: any) => {
    service
      .updateEmissionFactor(payload)
      .then((res) => {
        const result = res.status;
        if (result === 200) {
          message.success("배출계수가 수정 되었습니다.");
        } else {
          message.error("배출계수가 수정 되지 않았습니다.");
        }
      })
      .catch((error) => {
        message.error("배출계수가 수정 되지 않았습니다.");
      })
      .finally(() => {
        setIsModify(false);
        getEmissionFactor();
      });
  };

  const onDelete = (id: string) => {
    service
      .deleteEmissionFactor(id)
      .then((res) => {
        console.log("@@@ res", res);
        const result = res.status;
        if (result === 200) {
          message.success("배출계수가 삭제 되었습니다.");
        } else {
          message.error("배출계수가 삭제 되지 않았습니다.");
        }
      })
      .catch((error) => {
        message.error("배출계수가 삭제 되지 않았습니다.");
      })
      .finally(() => {
        getEmissionFactor();
      });
  };

  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <FactorSearchComponent onSearch={getEmissionFactor} />
      <div
        style={{
          display: "flex",
          margin: "10px 0px",
        }}
      >
        <div>
          <Button
            type="primary"
              className="searchbtn"
            onClick={onCreate}
            disabled={selectData !== null}
          >
            생성
          </Button>
          <Button
            type="primary"
              className="searchbtn"
            onClick={onCreate}
            style={{ marginLeft: "10px" }}
            disabled={selectData === null}
          >
            수정
          </Button>
          <Button
            type="primary"
              className="searchbtn"
            style={{ marginLeft: "10px" }}
            // onClick={onDelete}
            onClick={showConfirm}
            disabled={selectData === null}
          >
            삭제
          </Button>
        </div>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={fColumn}
      />
      {isModify && (
        <ModifyModal
          visible={isModify}
          paramfactors={factors}
          handleCancel={handleCancel}
          onInsert={onInsert}
          onUpdate={onUpdate}
          obj={selectData}
        />
      )}
    </div>
  );
};

export default EmissionCoefficent;
