import React from "react";

const MCsu = () => {
  return (
    <svg
      id="_\uB808\uC774\uC5B4_1"
      xmlns="http://www.w3.org/2000/svg"
      width="150px"
      height="115px"
      viewBox="0 0 150 115"
      //   {...props}
    >
      <defs>
        <style>
          {
            ".m-csu-1{stroke-miterlimit:10;}.m-csu-1,.m-csu-2{fill:none;stroke:#f0f0f0;stroke-width:1.5px;}.m-csu-2{stroke-linejoin:bevel;}"
          }
        </style>
      </defs>
      <g id="_\uADF8\uB8F9_201">
        <g id="_\uADF8\uB8F9_200">
          <g id="_\uADF8\uB8F9_197">
            <path
              id="_\uD328\uC2A4_535"
              className="m-csu-1"
              d="M143.37,97.73H58.69v.36"
            />
          </g>
          <g id="_\uADF8\uB8F9_199">
            <rect
              id="_\uC0AC\uAC01\uD615_109"
              className="m-csu-1"
              x={12.75}
              y={84.19}
              width={5.68}
              height={1.91}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_110"
              className="m-csu-1"
              x={13.39}
              y={43.12}
              width={4.41}
              height={41.08}
            />
            <path
              id="_\uD328\uC2A4_536"
              className="m-csu-1"
              d="M89.76,43.12H7.63v-2.2H90.69l-.93,2.2Z"
            />
            <rect
              id="_\uC0AC\uAC01\uD615_111"
              className="m-csu-1"
              x={11.34}
              y={28.74}
              width={8.58}
              height={12.18}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_112"
              className="m-csu-1"
              x={11.3}
              y={39.58}
              width={8.58}
              height={1.33}
            />
            <path
              id="_\uD328\uC2A4_537"
              className="m-csu-1"
              d="M84.03,56.89h18.52l-8.89-22.95-9.63,22.95h18.52"
            />
            <path
              id="_\uD328\uC2A4_538"
              className="m-csu-1"
              d="M98.03,45.15h-9.07l1.61-3.83h5.93l1.53,3.83Z"
            />
            <rect
              id="_\uC0AC\uAC01\uD615_113"
              className="m-csu-1"
              x={79.44}
              y={56.89}
              width={28.06}
              height={2.15}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_114"
              className="m-csu-1"
              x={79.44}
              y={59.04}
              width={28.06}
              height={2.15}
            />
            <path
              id="_\uD328\uC2A4_539"
              className="m-csu-1"
              d="M134.08,32.39l-40.28,1.88,1.35,3.47,38.93-2.29v-3.07Z"
            />
            <rect
              id="_\uC0AC\uAC01\uD615_115"
              className="m-csu-1"
              x={134.08}
              y={29.2}
              width={10.15}
              height={8.55}
            />
            <line
              id="_\uC120_108"
              className="m-csu-1"
              x1={83.35}
              y1={43.23}
              x2={84.51}
              y2={55.76}
            />
            <path
              id="_\uD328\uC2A4_540"
              className="m-csu-2"
              d="M93.8,33.94H20.5L97.49,16.91l35.83,15.48-39.52,1.55Z"
            />
            <path
              id="_\uD328\uC2A4_541"
              className="m-csu-1"
              d="M105.61,33.48l-8.11-16.56-13.57,17.03"
            />
            <line
              id="_\uC120_109"
              className="m-csu-1"
              x1={93.8}
              y1={33.94}
              x2={97.49}
              y2={16.91}
            />
            <path
              id="_\uD328\uC2A4_542"
              className="m-csu-1"
              d="M66.54,97.73V61.19h54.26v36.54h-2.67l-2.78-31.42h-43.6l-2.9,31.42h-2.31Z"
            />
            <g id="_\uADF8\uB8F9_198">
              <path
                id="_\uD328\uC2A4_543"
                className="m-csu-1"
                d="M6.94,92.17v1.62h7.13s2.73-.06,2.73-1.97v-5.51h-2.2v4.29s.29,1.45-.52,1.45l-7.14,.12Z"
              />
              <line
                id="_\uC120_110"
                className="m-csu-1"
                x1={13.55}
                y1={88.11}
                x2={14.4}
                y2={88.11}
              />
              <line
                id="_\uC120_111"
                className="m-csu-1"
                x1={16.94}
                y1={88.11}
                x2={17.79}
                y2={88.11}
              />
              <line
                id="_\uC120_112"
                className="m-csu-1"
                x1={13.55}
                y1={90.05}
                x2={14.4}
                y2={90.05}
              />
              <line
                id="_\uC120_113"
                className="m-csu-1"
                x1={16.12}
                y1={93.44}
                x2={16.62}
                y2={94.13}
              />
              <line
                id="_\uC120_114"
                className="m-csu-1"
                x1={6.34}
                y1={91.38}
                x2={6.88}
                y2={92.12}
              />
              <line
                id="_\uC120_115"
                className="m-csu-1"
                x1={6.93}
                y1={93.92}
                x2={6.33}
                y2={94.62}
              />
              <line
                id="_\uC120_116"
                className="m-csu-1"
                x1={5.78}
                y1={93.21}
                x2={6.63}
                y2={93.21}
              />
              <line
                id="_\uC120_117"
                className="m-csu-1"
                x1={8.75}
                y1={90.93}
                x2={8.75}
                y2={91.78}
              />
              <line
                id="_\uC120_118"
                className="m-csu-1"
                x1={8.75}
                y1={94.19}
                x2={8.75}
                y2={95.04}
              />
              <line
                id="_\uC120_119"
                className="m-csu-1"
                x1={10.75}
                y1={90.93}
                x2={10.75}
                y2={91.78}
              />
              <line
                id="_\uC120_120"
                className="m-csu-1"
                x1={10.75}
                y1={94.19}
                x2={10.75}
                y2={95.04}
              />
              <line
                id="_\uC120_121"
                className="m-csu-1"
                x1={12.75}
                y1={90.93}
                x2={12.75}
                y2={91.78}
              />
              <line
                id="_\uC120_122"
                className="m-csu-1"
                x1={12.75}
                y1={94.19}
                x2={12.75}
                y2={95.04}
              />
              <line
                id="_\uC120_123"
                className="m-csu-1"
                x1={14.3}
                y1={93.79}
                x2={14.3}
                y2={95.04}
              />
              <line
                id="_\uC120_124"
                className="m-csu-1"
                x1={16.94}
                y1={90.05}
                x2={17.79}
                y2={90.05}
              />
              <line
                id="_\uC120_125"
                className="m-csu-1"
                x1={16.94}
                y1={91.78}
                x2={17.79}
                y2={91.78}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MCsu;
