import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select } from "antd";
import service from "@services/carbontaxService";
import { carbonSearchState } from "../../../store/atom";
import { useRecoilState } from "recoil";
const { TextArea } = Input;

const ModifyModal = ({
  visible,
  handleCancel,
  onInsert,
  onUpdate,
  obj,
  paramfactors,
}: any) => {
  const [form] = Form.useForm();

  const [type, setType] = useState<string>("emission");
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...obj,
      type,
    });
  }, []);

  const [searchObj, setSearchObj] = useRecoilState<any>(carbonSearchState);

  const findId = () => {
    if (searchObj.scope === "direct emissions source") {
      if (searchObj.activityScope === "stationary") {
        return 1;
      } else if (searchObj.activityScope === "mobile combustion") {
        if (searchObj.activity === "solid fuel combustion") {
          return 9;
        } else {
          if (searchObj.source === "gasoline") {
            return 2;
          } else {
            return 7;
          }
        }
      } else if (searchObj.activityScope === "heat/steam") {
        return 11;
      } else if (searchObj.activityScope === "electricity") {
        if (searchObj.activity === "mobile combustion(airline)") {
          return 8;
        } else {
          return 12;
        }
      }
    } else {
      if (searchObj.activityScope === "heat/steam") {
        return 13;
      } else if (searchObj.activityScope === "electricity") {
        return 3;
      } else {
        return 4;
      }
    }
  };

  const scopeList = ["direct emissions source", "Indirect emissions source"];
  const activityScopeList = {
    "direct emissions source": [
      "stationary combustion",
      "mobile combustion",
      "electricity",
      "heat/steam",
    ],
    "Indirect emissions source": ["electricity", "steam(GT)", "heat/steam"],
  };

  const activityList = {
    "stationary combustion": "solid fuel combustion",
    "steam(GT)": "external heat(A Type)",
    "mobile combustion": [
      "mobile combustion(airline)",
      "solid fuel combustion",
    ],
    electricity: [
      "Indirect emissions(external electricity)",
      "mobile combustion(airline)",
      "external heat(A Type)",
    ],
    "heat/steam": [
      "combustion(airline)",
      "Indirect emissions(external electricity)",
    ],
  };

  const sourceList = {
    activity: "source",
    "solid fuel combustion": ["natural gas(liqeur)", "electricity"],
    "mobile combustion(airline)": ["gasoline", "electricity"],
    "Indirect emissions(external electricity)": ["electricity"],
    "external heat(A Type)": ["heat/steam", "electricity"],
  };

  const renderOptions = (obj: any) => {
    return obj.map((value: string) => ({ value, label: value }));
  };

  const onFinishForm = (value: any) => {
    let values = {};
    for (let factor of factors) {
      values[factor] = value[factor];
    }
    const result = {
      type: value.type,
      description: value.description,
      version: value.version,
      tier: value.tier,
      formula: value.formula,
      values: JSON.stringify(values),
    };
    const coEmissionId = findId();
    if (obj !== null) {
      onUpdate({ id: obj.id, coEmissionId, ...result });
    } else {
      onInsert({ coEmissionId, ...result });
    }
  };

  const [title, setTitle] = useState<string>();
  useEffect(() => {
    if (!obj) {
      setTitle("배출 계수 추가");
    } else {
      setTitle("배출 계수 수정");
    }
  }, []);

  const validateMessages = {
    required: "'${label}'을 입력하세요.",
  };

  const [factors, setFactors] = useState<any>();

  const getFactorList = () => {
    service.getTypeHeader(type).then((res) => {
      const results = res.data.headers;
      setFactors(results);
    });
  };

  useEffect(() => {
    setFactors(paramfactors);
    getFactorList();
  }, []);

  useEffect(() => {
    getFactorList();
  }, [type]);

  return (
    <Modal
      className="common"
      visible={visible}
      title={title}
      open={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      okText="저장"
      cancelText="취소"
    >
      <Form
        labelCol={{ span: 6, offset: 1 }}
        wrapperCol={{ span: 20, offset: 1 }}
        form={form}
        onFinish={onFinishForm}
        // validateMessages={validateMessages}
      >
        <Form.Item name="coefficentId" label="id" hidden={true}></Form.Item>
        <Form.Item
          name="description"
          label="표준"
          // rules={[{ required: true }]}
        >
          <Select options={[{ value: "ISO", lable: "ISO" }]} />
        </Form.Item>
        <Form.Item
          name="version"
          label="버전"
          // rules={[{ required: true }]}
        >
          <Select options={[{ value: "1", lable: "1" }]} />
        </Form.Item>
        <Form.Item name="tier" label="Tier" rules={[{ required: true }]}>
          <Select
            options={[
              { value: 1, label: 1 },
              { value: 2, label: 2 },
              { value: 3, label: 3 },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="scope"
          label="SCOPE" // rules={[{ required: true }]}
        >
          <Select
            value={searchObj.scope}
            onChange={(value: string) => {
              setSearchObj({ ...searchObj, scope: value });
            }}
            options={renderOptions(scopeList)}
          />
        </Form.Item>
        <Form.Item
          name="activityScope"
          label="배출 활동 구분"
          rules={[{ required: true }]}
        >
          <Select
            value={searchObj.activityScope}
            onChange={(value: string) => {
              setSearchObj({ ...searchObj, activityScope: value });
            }}
            options={renderOptions(activityScopeList[searchObj.scope])}
          />
        </Form.Item>
        <Form.Item
          name="activity"
          label="배출 활동"
          rules={[{ required: true }]}
        >
          <Select
            value={searchObj.activity}
            onChange={(value: string) => {
              setSearchObj({ ...searchObj, activity: value });
            }}
            options={renderOptions(activityList[searchObj.activityScope])}
          />
        </Form.Item>
        <Form.Item name="source" label="활동 자료" rules={[{ required: true }]}>
          <Select
            value={searchObj.source}
            onChange={(value: string) => {
              setSearchObj({ ...searchObj, source: value });
            }}
            options={renderOptions(sourceList[searchObj.activity])}
          />
        </Form.Item>
        <Form.Item
          name="formula"
          label="산정 공식"
          rules={[{ required: true }]}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item name="type" label="타입" rules={[{ required: true }]}>
          <Select
            value={type}
            defaultValue={type}
            onChange={(value: string) => {
              setType(value);
            }}
            options={[
              {
                value: "emission",
                label: "emission",
              },
              {
                value: "fever",
                label: "fever",
              },
              {
                value: "oil",
                label: "oil",
              },
            ]}
          />
        </Form.Item>
        {factors &&
          factors.map((factor: string) => (
            <>
              <Form.Item name={factor} label={factor}>
                <Input type="text"></Input>
              </Form.Item>
            </>
          ))}
      </Form>
    </Modal>
  );
};

export default ModifyModal;
