import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "@styles/carbon/chartModal.module.css";

const EqpChartModal = ({ isModalOpen, handleOk, eqpName }: any) => {
  return (
    <Modal
      className="charModal"
      closable={false}
      visible={isModalOpen}
      width={1500}
      bodyStyle={{ background: "#1B1E46", borderRadius: "10px" }}
      footer={null}
    >
      <div className={styles.template}>
        <div className={styles.title}>
          <div>{`[ ${eqpName} ] Equipment Trend`}</div>
          <div
            className={styles.hover}
            onClick={() => {
              handleOk();
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "30px" }}>
            <iframe
              title="i"
              width="1310px"
              height="600px"
              src={`https://aipim.synology.me:4321/trend/embed?eqp=${eqpName}`}
            ></iframe>
          </div>

          <div>Health Status</div>
        </div>
      </div>
    </Modal>
  );
};

export default EqpChartModal;
