import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import HeaderBar from "@components/HeaderBar";
import SideBar from "@components/SideBar";

const { Content } = Layout;

const DefaultLayout = () => {
  return (
    <Layout id="default-layout">
      <SideBar />
      <Layout id="site-layout">
        <HeaderBar/>
        <Content id={"site-content"}>
          <Outlet/>
        </Content>
        <div id="site-footer">
          <span>v.{process.env.REACT_APP_VERSION}</span>
        </div>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
