import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import dayjs from "dayjs";

// recoil-persist 설정: 상태를 로컬 스토리지에 저장
const { persistAtom } = recoilPersist({
  key: "sessionStorage",
  storage: localStorage,
});

// 사이드바 접힘/펼침 상태
export const collapsedState = atom({
  key: "collapsed",
  default: false,
});

// 헤더 정보 상태
export const headerState = atom<any>({
  key: "headerState",
  default: {
    title: "title",
    subTitle: "subTitle",
    subSubTitle: "subSubTitle",
    path: "/",
    isGoBackVisible: false,
  },
});

// 로그인 상태
export const isLoginState = atom({
  key: "isLoginState",
  default: false,
});

// 사용자 권한 상태
export const authoritiesState = atom({
  key: "authoritiesState",
  default: [],
});

// 토큰 인터페이스
export interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

// 로그인 성공 페이로드 인터페이스
export interface LoginSuccessPayload {
  tokens: Tokens;
  userId: number;
  siteId: number;
  authorities: [];
}

// 전체 상태 인터페이스
export interface State {
  isLogin: boolean;
  tokens: Tokens | null;
  userId: number | null;
  siteId: number | null;
  authorities: string[];
}

// 초기 상태 설정
const initialToken: Tokens = {
  accessToken: null,
  refreshToken: null,
};

const initialState: State = {
  isLogin: false,
  tokens: initialToken,
  userId: null,
  siteId: null,
  authorities: [],
};

// 로그인 성공 상태
export const loginSuccess = atom<any>({
  key: "loginSuccess",
  default: initialState,
});

// 인증 셀렉터: 로그인 상태를 가져오고 설정하는 역할
export const authSelector = selector({
  key: "authSelector",
  get: ({ get }) => get(loginSuccess),
  set: ({ set }, authObj) => {
    if (authObj) {
      set(loginSuccess, { ...authObj, isLogin: true });
    } else {
      set(loginSuccess, initialState);
    }
  },
});

// 회사 목록 상태 (로컬 스토리지에 저장)
export const companyListState = atom<any>({
  key: "companyList",
  default: {
    sendObj: { "N/A": ["N/A"] },
    receiveObj: { "N/A": ["N/A"] },
  },
  effects_UNSTABLE: [persistAtom],
});

// 검색 상태
export const searchState = atom<any>({
  key: "searchState",
  default: {
    startDateTime: dayjs().format("YYYYMMDD") + "000000",
    endDateTime: dayjs()
      .startOf("day")
      .add(24, "hour")
      .format("YYYYMMDDHHmmss"),
    trnsUserId: "",
    trnsSystemId: "",
    rcptnUserId: "",
    rcptnSystemId: "",
    status: "",
    datasetName: "",
    dataItemName: "",
    page: 1,
    size: 10,
  },
});

// 검색 상태
export const errorSearchState = atom<any>({
  key: "errorSearchState",
  default: {
    startDateTime: dayjs().subtract(6, "days").format("YYYYMMDD") + "000000",
    endDateTime: dayjs()
      .startOf("day")
      .add(24, "hour")
      .format("YYYYMMDDHHmmss"),
    trnsUserId: "",
    trnsSystemId: "",
    rcptnUserId: "",
    rcptnSystemId: "",
    status: "",
    datasetName: "",
    dataItemName: "",
    page: 1,
    size: 100,
  },
});

// TOBE : auth에서 송신 기업 정보를 받아 오는 것이 연결 되어 있지 않기 때문에 하드코딩 해놓음
// 송신 회사 상태
export const trnCompanyState = atom<any>({
  key: "trnCompany",
  default: [{ value: "1", label: "포스코" }],
});

// 송신 시스템 상태
export const trnSystemState = atom<any>({
  key: "trnSystem",
  default: [{ value: "", label: "" }],
});

// 수신 회사 상태
export const rcpCompanyState = atom<any>({
  key: "rcpCompany",
  default: [{ value: "", label: "" }],
});

// 수신 시스템 상태
export const rcpSystemState = atom<any>({
  key: "rcpSystem",
  default: [{ value: "", label: "" }],
});

// 탄소 검색 상태
export const carbonSearchState = atom<any>({
  key: "carbonSearchState",
  default: {
    scope: "Indirect emissions source",
    activityScope: "electricity",
    activity: "Indirect emissions(external electricity)",
    source: "electricity",
    type: "emission",
  },
});
