import React from "react";
import styles from "@styles/dataShare/dataDashboard.module.css";

const ChartCard = ({ title, subTitle, content }: any) => {
  return (
    <div className={styles.chartCard}>
      <div className={styles.title}>
        {title}
        {subTitle && <span className={styles.subTitle}>{`(${subTitle})`}</span>}
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default ChartCard;
