import React, { useEffect, useState } from "react";
import { headerState, searchState } from "../../../store/atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import type { PaginationProps } from "antd";
import service from "@services/dataShareService";
import SearchComponent from "../SearchComponent";
import dayjs from "dayjs";

const DataCollectionStatus = () => {
  const { t } = useTranslation();

  const setStatus = (data: string) => {
    let result = "";
    switch (data) {
      case "":
        result = "전체";
        break;
      case null:
        result = "-";
        break;
      case "Y":
        result = "완료";
        break;
      case "N":
        result = "수집중";
        break;
      default:
        result = "-";
        break;
    }
    return result;
  };

  const textCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }
    const result = a.localeCompare(b);
    return result;
  };

  const dateCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }

    const unixA = dayjs(a).valueOf();
    const unixB = dayjs(b).valueOf();

    if (unixA === unixB) return 0;
    if (unixA > unixB) {
      return 1;
    } else {
      return -1;
    }
  };

  const sendCol: any = [
    {
      align: "right",
      width: "40px",
      title: "No.",
      dataIndex: "key",
    },
    {
      align: "left",
      title: t("main:shareData:sendingCompany"),
      dataIndex: "trnsUserNm",
      key: "trnsUserNm",
      sorter: (a: any, b: any) => textCompare(a.trnsUserNm, b.trnsUserNm),
    },
    {
      align: "left",
      title: t("main:shareData:sendingSystem"),
      dataIndex: "trnsSysNm",
      key: "trnsSysNm",
      sorter: (a: any, b: any) => textCompare(a.trnsSysNm, b.trnsSysNm),
    },
    {
      align: "left",
      title: t("main:shareData:receivingCompany"),
      dataIndex: "rcptnUserNm",
      key: "rcptnUserNm",
      sorter: (a: any, b: any) => textCompare(a.rcptnUserNm, b.rcptnUserNm),
    },
    {
      align: "left",
      title: t("main:shareData:receivingSystem"),
      dataIndex: "rcptnSysNm",
      key: "rcptnSysNm",
      sorter: (a: any, b: any) => textCompare(a.rcptnSysNm, b.rcptnSysNm),
      ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:datasetName"),
      dataIndex: "datasetName",
      key: "datasetName",
      sorter: (a: any, b: any) => textCompare(a.datasetName, b.datasetName),
      ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:datasetId"),
      dataIndex: "datasetId",
      key: "datasetId",
      sorter: (a: any, b: any) => textCompare(a.datasetId, b.datasetId),
      ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:sharedDataId"),
      dataIndex: "dataSharId",
      key: "dataSharId",
      sorter: (a: any, b: any) => textCompare(a.dataSharId, b.dataSharId),
      ellipsis: true,
    },
    {
      align: "left",
      title: t("main:shareData:sendingType"),
      dataIndex: "trnsMthd",
      key: "trnsMthd",
      sorter: (a: any, b: any) => textCompare(a.trnsMthd, b.trnsMthd),
    },
    {
      width: "150px",
      align: "center",
      title: t("main:shareData:sendingDate"),
      dataIndex: "trnsDt",
      key: "trnsDt",
      sorter: (a: any, b: any) => dateCompare(a.trnsDt, b.trnsDt),
      render: (record: any) => {
        return (
          <div>
            {dayjs(record).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      align: "left",
      title: t("main:shareData:receivingType"),
      dataIndex: "rcptnMthd",
      key: "rcptnMthd",
      sorter: (a: any, b: any) => textCompare(a.rcptnMthd, b.rcptnMthd),
    },
    {
      width: "150px",
      align: "center",
      title: t("main:shareData:receivingDate"),
      dataIndex: "rcptnDt",
      key: "rcptnDt",
      sorter: (a: any, b: any) => dateCompare(a.rcptnDt, b.rcptnDt),
      render: (record: any) => {
        let date = "-";
        if (record) {
          date = dayjs(record).format("YYYY-MM-DD HH:mm:ss");
        }
        return (
          <div>
            {date}
          </div>
        );
      },
    },
    {
      align: "left",
      title: t("main:shareData:receivingStatus"),
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => textCompare(a.status, b.status),
      render: (record: any) => {
        return (
          <div>
            {setStatus(record)}
          </div>
        );
      },
    },
  ];

  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:shareData"),
      subTitle: t("main:header:shareDataStatus"),
      path: "/shareData/status",
    });
  }, []);

  const [searchCondition, setSearchCondition] = useRecoilState(searchState);

  const [dataList, setDataList] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  const onSearch = () => {
    getDataStatusList();
  };

  const getDataStatusList = () => {
    const payload = { ...searchCondition, page, size };
    delete payload.endDateTime;
    delete payload.startDateTime;
    service.getDataStatusList(payload).then((res) => {
      const data = res.data.list;
      setTotalCount(res.data.totalCount);
      const _size = res.data.pageSize;
      setSize(res.data.pageSize);
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 + (page - 1) * _size };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber);
    setSearchCondition({ ...searchCondition, page: pageNumber, size });
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    setSize(pageSize);
    setSearchCondition({ ...searchCondition, page: current, size: pageSize });
  };

  useEffect(() => {
    getDataStatusList();
  }, [size, page]);

  return (
    <div>
      <SearchComponent onSearch={onSearch} />
      <Table
        className="dataShare"
        style={{ marginTop: "10px" }}
        dataSource={dataList}
        columns={sendCol}
        size="small"
        pagination={{
          onShowSizeChange: onShowSizeChange,
          current: page,
          onChange: onChange,
          total: totalCount,
          defaultCurrent: 1,
          pageSize: size,
        }}
      />
    </div>
  );
};

export default DataCollectionStatus;
