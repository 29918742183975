import React from "react";
import { DeploymentUnitOutlined, GlobalOutlined } from "@ant-design/icons";
import { Layout, Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { collapsedState } from "@store/atom";
import { useTranslation } from "react-i18next";
import logo from "@assets/images/posco_logo.png";
import logo_sm from "@assets/images/posco_logo_sm.png";
import { IoAnalytics, IoShareSocialOutline } from "react-icons/io5";

const { Sider } = Layout;
const omsUrl = process.env.REACT_APP_OMS_URL;

const createLabel = (title: string, link: string) => (
  <Link to={link}>{title}</Link>
);

const SideBar = () => {
  const { t } = useTranslation();
  const collapsed = useRecoilValue(collapsedState);

  const items: MenuProps["items"][number][] = [
    {
      key: "1",
      label: t("main:header:shareData"),
      icon: <IoShareSocialOutline />,
      children: [
        {
          key: "1-1",
          label: createLabel(
            t("main:header:dashboard"),
            "/shareData/dashboard"
          ),
        },
        {
          key: "1-2",
          label: createLabel(
            t("main:header:shareDataStatus"),
            "/shareData/status"
          ),
        },
        {
          key: "1-3",
          label: createLabel(
            t("main:header:shareDataHistory"),
            "/shareData/history"
          ),
        },
        {
          key: "1-4",
          label: createLabel(t("main:header:userInfo"), "/shareData/userInfo"),
        },
        {
          key: "1-5",
          label: createLabel(
            t("main:header:errorLogHistory"),
            "/shareData/errorlog"
          ),
        },
      ],
    },
    {
      key: "2",
      label: t("main:header:analysisData"),
      icon: <IoAnalytics />,
      children: [
        {
          key: "2-1",
          label: createLabel(t("main:header:metric"), "/analysisData/metric"),
        },
        {
          key: "2-2",
          label: createLabel(t("main:header:device"), "/analysisData/device"),
        },
      ],
    },
  ];

  const extraItems = [
    {
      key: "3",
      label: t("main:header:optimumFacility"),
      icon: <DeploymentUnitOutlined />,
      children: [
        {
          key: "3-1",
          label: createLabel(
            t("main:header:dashboard"),
            "/optimumFacility/dashboard"
          ),
        },
        {
          key: "3-2",
          label: createLabel(t("main:header:trend"), "/optimumFacility/trend"),
        },
      ],
    },
    {
      key: "4",
      label: t("main:header:carbonEmissions"),
      icon: <GlobalOutlined />,
      children: [
        {
          key: "4-1",
          label: createLabel(
            t("main:header:dashboard"),
            "/carbonTax/dashboard"
          ),
        },
        {
          key: "4-2",
          label: createLabel(
            t("main:header:emissionsCal"),
            "/carbonTax/emissionsCal"
          ),
        },
        {
          key: "4-3",
          label: createLabel(
            t("main:header:variationTrend"),
            "/carbonTax/variationTrend"
          ),
        },
        {
          key: "4-4",
          label: createLabel(
            t("main:header:emissionCoefficent"),
            "/carbonTax/emissionCoefficent"
          ),
        },
        {
          key: "4-5",
          label: createLabel(
            t("main:header:relationManage"),
            "/carbonTax/relationManage"
          ),
        },
        {
          key: "4-6",
          label: createLabel(
            t("main:header:traceability"),
            "/carbonTax/traceability"
          ),
        },
        {
          key: "4-7",
          label: createLabel(t("main:header:report"), "/carbonTax/report"),
        },
      ],
    },
  ];

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <a href="/" target="_blank" rel="noopener noreferrer">
          {!collapsed ? (
            <img src={logo} alt="logo" width={125} height={33} />
          ) : (
            <img src={logo_sm} alt="logo" width={26} height={33} />
          )}
        </a>
      </div>
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={["1-2"]}
        items={[...items, ...(omsUrl ? extraItems : [])]}
        defaultOpenKeys={["1", "2", "3", "4"]}
      />
    </Sider>
  );
};

export default SideBar;
