import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const TraceBarChart = () => {
  const [option, setOption] = useState<any>(null);
  useEffect(() => {
    getOption();
  }, []);

  const getOption = () => {
    const chartOptions: ApexOptions = {
      title: {
        // text: title,
        align: "left",
        //offsetY: 40,
        style: {
          fontSize: "24px",
          fontWeight: "normal",
          fontFamily: "Noto Sans KR",
          color: "#F0F0F0",
        },
      },

      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Noto Sans KR",
        },
        marker: {
          show: true,
        },
      },
      chart: {
        zoom: {
          enabled: false,
        },

        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 4,
      },
      xaxis: {
        categories: [
          "2023.03.07 11:06:47",
          "2023.03.07 11:06:48",
          "2023.03.07 11:06:49",
          "2023.03.07 11:06:50",
          "2023.03.07 11:06:51",
          "2023.03.07 11:06:52",
          "2023.03.07 11:06:53",
          "2023.03.07 11:06:54",
          "2023.03.07 11:06:55",
          "2023.03.07 11:06:56",
          "2023.03.07 11:06:57",
          "2023.03.07 11:06:58",
          "2023.03.07 11:06:59",
          "2023.03.07 11:07:00",
          "2023.03.07 11:07:01",
          "2023.03.07 11:07:02",
          "2023.03.07 11:07:03",
          "2023.03.07 11:07:04",
          "2023.03.07 11:07:05",
          "2023.03.07 11:07:06",
          "2023.03.07 11:07:07",
          "2023.03.07 11:07:08",
          "2023.03.07 11:07:09",
          "2023.03.07 11:07:10",
          "2023.03.07 11:07:11",
          "2023.03.07 11:07:12",
          "2023.03.07 11:07:13",
          "2023.03.07 11:07:14",
          "2023.03.07 11:07:15",
          "2023.03.07 11:07:16",
          "2023.03.07 11:07:17",
          "2023.03.07 11:07:18",
          "2023.03.07 11:07:19",
          "2023.03.07 11:07:20",
          "2023.03.07 11:07:21",
          "2023.03.07 11:07:22",
          "2023.03.07 11:07:23",
          "2023.03.07 11:07:24",
          "2023.03.07 11:07:25",
          "2023.03.07 11:07:26",
          "2023.03.07 11:07:27",
          "2023.03.07 11:07:28",
          "2023.03.07 11:07:29",
          "2023.03.07 11:07:30",
          "2023.03.07 11:07:31",
          "2023.03.07 11:07:32",
          "2023.03.07 11:07:33",
          "2023.03.07 11:07:34",
          "2023.03.07 11:07:35",
          "2023.03.07 11:07:36",
          "2023.03.07 11:07:37",
          "2023.03.07 11:07:38",
          "2023.03.07 11:07:39",
          "2023.03.07 11:07:40",
          "2023.03.07 11:07:41",
          "2023.03.07 11:07:42",
          "2023.03.07 11:07:43",
          "2023.03.07 11:07:44",
          "2023.03.07 11:07:45",
          "2023.03.07 11:07:46",
          "2023.03.07 11:07:47",
          "2023.03.07 11:07:48",
          "2023.03.07 11:07:49",
          "2023.03.07 11:07:50",
          "2023.03.07 11:07:51",
          "2023.03.07 11:07:52",
          "2023.03.07 11:07:53",
          "2023.03.07 11:07:54",
          "2023.03.07 11:07:55",
          "2023.03.07 11:07:56",
          "2023.03.07 11:07:57",
          "2023.03.07 11:07:58",
          "2023.03.07 11:07:59",
          "2023.03.07 11:08:00",
          "2023.03.07 11:08:01",
          "2023.03.07 11:08:02",
          "2023.03.07 11:08:03",
          "2023.03.07 11:08:04",
          "2023.03.07 11:08:05",
          "2023.03.07 11:08:06",
          "2023.03.07 11:08:07",
          "2023.03.07 11:08:08",
          "2023.03.07 11:08:09",
          "2023.03.07 11:08:10",
          "2023.03.07 11:08:11",
          "2023.03.07 11:08:12",
          "2023.03.07 11:08:13",
          "2023.03.07 11:08:14",
          "2023.03.07 11:08:15",
          "2023.03.07 11:08:16",
          "2023.03.07 11:08:17",
          "2023.03.07 11:08:18",
          "2023.03.07 11:08:19",
          "2023.03.07 11:08:20",
          "2023.03.07 11:08:21",
          "2023.03.07 11:08:22",
          "2023.03.07 11:08:23",
          "2023.03.07 11:08:24",
          "2023.03.07 11:08:25",
          "2023.03.07 11:08:26",
          "2023.03.07 11:08:27",
          "2023.03.07 11:08:28",
          "2023.03.07 11:08:30",
          "2023.03.07 11:08:31",
          "2023.03.07 11:08:32",
          "2023.03.07 11:08:33",
          "2023.03.07 11:08:34",
          "2023.03.07 11:08:35",
          "2023.03.07 11:08:36",
          "2023.03.07 11:08:37",
          "2023.03.07 11:08:38",
          "2023.03.07 11:08:39",
          "2023.03.07 11:08:40",
          "2023.03.07 11:08:41",
          "2023.03.07 11:08:42",
          "2023.03.07 11:08:43",
          "2023.03.07 11:08:44",
          "2023.03.07 11:08:45",
          "2023.03.07 11:08:46",
          "2023.03.07 11:08:47",
          "2023.03.07 11:08:48",
          "2023.03.07 11:08:49",
          "2023.03.07 11:08:50",
          "2023.03.07 11:08:51",
          "2023.03.07 11:08:52",
          "2023.03.07 11:08:53",
          "2023.03.07 11:08:54",
          "2023.03.07 11:08:55",
          "2023.03.07 11:08:56",
          "2023.03.07 11:08:57",
          "2023.03.07 11:08:58",
          "2023.03.07 11:08:59",
          "2023.03.07 11:09:00",
          "2023.03.07 11:09:01",
          "2023.03.07 11:09:02",
          "2023.03.07 11:09:03",
          "2023.03.07 11:09:04",
          "2023.03.07 11:09:05",
          "2023.03.07 11:09:06",
          "2023.03.07 11:09:07",
          "2023.03.07 11:09:08",
          "2023.03.07 11:09:09",
          "2023.03.07 11:09:10",
          "2023.03.07 11:09:11",
          "2023.03.07 11:09:12",
          "2023.03.07 11:09:13",
          "2023.03.07 11:09:14",
          "2023.03.07 11:09:15",
          "2023.03.07 11:09:16",
          "2023.03.07 11:09:17",
          "2023.03.07 11:09:18",
          "2023.03.07 11:09:19",
          "2023.03.07 11:09:20",
          "2023.03.07 11:09:21",
          "2023.03.07 11:09:22",
          "2023.03.07 11:09:23",
          "2023.03.07 11:09:24",
          "2023.03.07 11:09:25",
          "2023.03.07 11:09:26",
          "2023.03.07 11:09:27",
          "2023.03.07 11:09:28",
          "2023.03.07 11:09:29",
          "2023.03.07 11:09:30",
          "2023.03.07 11:09:31",
          "2023.03.07 11:09:32",
          "2023.03.07 11:09:33",
          "2023.03.07 11:09:34",
          "2023.03.07 11:09:35",
          "2023.03.07 11:09:36",
          "2023.03.07 11:09:37",
          "2023.03.07 11:09:38",
          "2023.03.07 11:09:39",
          "2023.03.07 11:09:40",
          "2023.03.07 11:09:41",
          "2023.03.07 11:09:42",
          "2023.03.07 11:09:43",
          "2023.03.07 11:09:44",
          "2023.03.07 11:09:45",
          "2023.03.07 11:09:46",
          "2023.03.07 11:09:47",
        ],
        labels: {
          style: {
            colors: "#F0F0F0",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            colors: "#F0F0F0",
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        labels: {
          colors: "#F0F0F0",
        },
        itemMargin: {
          horizontal: 5,
        },
      },
      series: [
        {
          name: "power",
          data: [
            61, 50, 61, 58, 53, 58, 51, 62, 61, 53, 62, 59, 60, 53, 59, 63, 61,
            55, 62, 58, 57, 59, 58, 64, 53, 55, 54, 62, 52, 62, 54, 51, 62, 58,
            57, 58, 58, 58, 64, 59, 52, 54, 57, 53, 60, 52, 63, 57, 58, 60, 57,
            55, 52, 50, 59, 60, 60, 63, 61, 53, 64, 64, 50, 53, 59, 63, 63, 63,
            62, 59, 52, 59, 57, 64, 51, 55, 55, 58, 59, 53, 62, 62, 58, 62, 53,
            53, 51, 64, 57, 54, 61, 54, 52, 53, 52, 56, 58, 52, 61, 63, 51, 57,
            56, 64, 52, 60, 64, 53, 61, 56, 52, 64, 64, 61, 57, 55, 60, 60, 62,
            57, 52, 64, 57, 62, 53, 64, 59, 54, 64, 55, 56, 50, 52, 57, 62, 51,
            57, 57, 60, 52, 60, 59, 59, 63, 52, 55, 52, 54, 61, 63, 61, 64, 62,
            51, 62, 63, 52, 64, 56, 62, 51, 54, 59, 55, 51, 59, 56, 52, 62, 56,
            59, 54, 55, 59, 50, 52, 64, 58, 58, 59,
          ],
        },
        {
          name: "emissions",
          data: [
            0.037778172, 0.030965715, 0.037778172, 0.035920229, 0.032823658,
            0.035920229, 0.031585029, 0.038397487, 0.037778172, 0.032823658,
            0.038397487, 0.036539544, 0.037158858, 0.032823658, 0.036539544,
            0.039016801, 0.037778172, 0.034062287, 0.038397487, 0.035920229,
            0.035300915, 0.036539544, 0.035920229, 0.039636115, 0.032823658,
            0.034062287, 0.033442972, 0.038397487, 0.032204344, 0.038397487,
            0.033442972, 0.031585029, 0.038397487, 0.035920229, 0.035300915,
            0.035920229, 0.035920229, 0.035920229, 0.039636115, 0.036539544,
            0.032204344, 0.033442972, 0.035300915, 0.032823658, 0.037158858,
            0.032204344, 0.039016801, 0.035300915, 0.035920229, 0.037158858,
            0.035300915, 0.034062287, 0.032204344, 0.030965715, 0.036539544,
            0.037158858, 0.037158858, 0.039016801, 0.037778172, 0.032823658,
            0.039636115, 0.039636115, 0.030965715, 0.032823658, 0.036539544,
            0.039016801, 0.039016801, 0.039016801, 0.038397487, 0.036539544,
            0.032204344, 0.036539544, 0.035300915, 0.039636115, 0.031585029,
            0.034062287, 0.034062287, 0.035920229, 0.036539544, 0.032823658,
            0.038397487, 0.038397487, 0.035920229, 0.038397487, 0.032823658,
            0.032823658, 0.031585029, 0.039636115, 0.035300915, 0.033442972,
            0.037778172, 0.033442972, 0.032204344, 0.032823658, 0.032204344,
            0.034681601, 0.035920229, 0.032204344, 0.037778172, 0.039016801,
            0.031585029, 0.035300915, 0.034681601, 0.039636115, 0.032204344,
            0.037158858, 0.039636115, 0.032823658, 0.037778172, 0.034681601,
            0.032204344, 0.039636115, 0.039636115, 0.037778172, 0.035300915,
            0.034062287, 0.037158858, 0.037158858, 0.038397487, 0.035300915,
            0.032204344, 0.039636115, 0.035300915, 0.038397487, 0.032823658,
            0.039636115, 0.036539544, 0.033442972, 0.039636115, 0.034062287,
            0.034681601, 0.030965715, 0.032204344, 0.035300915, 0.038397487,
            0.031585029, 0.035300915, 0.035300915, 0.037158858, 0.032204344,
            0.037158858, 0.036539544, 0.036539544, 0.039016801, 0.032204344,
            0.034062287, 0.032204344, 0.033442972, 0.037778172, 0.039016801,
            0.037778172, 0.039636115, 0.038397487, 0.031585029, 0.038397487,
            0.039016801, 0.032204344, 0.039636115, 0.034681601, 0.038397487,
            0.031585029, 0.033442972, 0.036539544, 0.034062287, 0.031585029,
            0.036539544, 0.034681601, 0.032204344, 0.038397487, 0.034681601,
            0.036539544, 0.033442972, 0.034062287, 0.036539544, 0.030965715,
            0.032204344, 0.039636115, 0.035920229, 0.035920229, 0.036539544,
          ],
        },
        {
          name: "threshold",
          data: [
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
          ],
        },
      ],
      colors: ["#0487D9", "#FFF466", "#E30039", "#01AB33"],
    };

    setOption(chartOptions);
  };

  return (
    option && (
      <div>
        <Chart
          options={option}
          type="line"
          series={option.series}
          height={"700"}
          width={"1400"}
        />
      </div>
    )
  );
};

export default TraceBarChart;
