import React, { useEffect } from "react";
import { DatePicker, Form, Input, Modal } from "antd";
import dayjs from "dayjs";

const CycleModal = ({ isCycleModal, handleCancel }: any) => {
  const [form] = Form.useForm();
  let now = dayjs();
  now.format();
  useEffect(() => {
    form.setFieldsValue({
      maintenanceCycle: 10000,
      statusCount: 10000,
      setTime: dayjs(now, "YYYY-MM-DD HH:mm:ss"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      centered
      className="cycle"
      title={"Change maintenance cycle time"}
      visible={isCycleModal}
      width={735}
      onOk={handleCancel}
      onCancel={handleCancel}
      okText="Save"
      footer={null}
      style={{ bottom: 50 }}
    >
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 10, offset: 0 }}
        form={form}
      >
        <Form.Item name="maintenanceCycle" label="Maintenance cycle(time) ">
          <Input type="number" placeholder="10000"></Input>
        </Form.Item>
        <Form.Item name="statusCount" label="Boot(time)">
          <Input type="number" placeholder="10000"></Input>
        </Form.Item>
        <Form.Item name="setTime" label="Reset">
          <DatePicker
            defaultValue={dayjs(now, "YYYY-MM-DD HH:mm:ss")}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CycleModal;
