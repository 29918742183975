import {
  createRemoteComponent,
  createRequires,
} from "@paciolan/remote-component";
import { resolve } from "./remote-component.config.js";

const requires = createRequires(resolve);
const RemoteComponentGlobal = createRemoteComponent({ requires });
const baseUrl =
  process.env.REACT_APP_REMOTE_URL /* <<-- modserv server url */ ||
  "/mod"
export const RemoteComponent = (props) => (
  <RemoteComponentGlobal
    {...props}
    url={baseUrl + "/remote.js"}
    treeApiUrl={baseUrl}
  />
);
