import React from "react";

const ReclaimerSvg = ({
  statusColor,
  titleColor,
  data,
  setIsEqpChart,
  setEqpName,
}) => {
  const showModal = () => {
    setIsEqpChart(true);
    setEqpName(data.eqpName);
  };

  return (
    data && (
      <div
        onClick={() => {
          showModal();
        }}
      >
        <svg
          id="_\uB808\uC774\uC5B4_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="40px"
          // height="60px"
          // viewBox="8 0 50 60"
          width="40px"
          height="43px"
          viewBox="0 0 65 55"
          xmlSpace="preserve"
          // {...props}
        >
          <style type="text/css">
            {`\n\t.re${titleColor}{fill:${statusColor};stroke:${statusColor};stroke-linejoin:bevel;}\n\t.st1${titleColor}{fill:none;stroke:${statusColor};stroke-miterlimit:10;}\n`}
          </style>
          <g id="_\uADF8\uB8F9_190-13">
            <g id="_\uADF8\uB8F9_189-13">
              <g id="_\uADF8\uB8F9_187-13">
                <path
                  id="_\uD328\uC2A4_505-13"
                  className={`re${titleColor}`}
                  d="M45,31h-6l2.9-7.2L45,31h-6"
                />
                <path
                  id="_\uD328\uC2A4_506-13"
                  className={`re${titleColor}`}
                  d="M40.5,27.3h2.9l-0.5-1.2H41L40.5,27.3L40.5,27.3L40.5,27.3z"
                />
              </g>
              <path
                id="_\uD328\uC2A4_507-13"
                className={`st1${titleColor}`}
                d="M55.1,23.4l-13,0.6l0.4,1.1l12.6-0.7V23.4L55.1,23.4L55.1,23.4z"
              />
              <rect
                id="_\uC0AC\uAC01\uD615_104-13"
                x={55.1}
                y={22.4}
                className={`st1${titleColor}`}
                width={3.3}
                height={2.7}
              />
              <g id="_\uADF8\uB8F9_188-13">
                <path
                  id="_\uD328\uC2A4_508-13"
                  className={`re${titleColor}`}
                  d="M42.3,23.8H18.6l24.9-5.4l11.6,4.9L42.3,23.8L42.3,23.8z"
                />
                <path
                  id="_\uD328\uC2A4_509-13"
                  className={`st1${titleColor}`}
                  d="M46.1,23.6l-2.6-5.2l-4.4,5.4"
                />
                <line
                  id="_\uC120_102-13"
                  className={`st1${titleColor}`}
                  x1={42.3}
                  y1={23.8}
                  x2={43.5}
                  y2={18.4}
                />
              </g>
              <rect
                id="_\uC0AC\uAC01\uD615_105-13"
                x={50.1}
                y={40.4}
                className={`st1${titleColor}`}
                width={0.4}
                height={1.2}
              />
              <rect
                id="_\uC0AC\uAC01\uD615_106-13"
                x={33.4}
                y={40.4}
                className={`st1${titleColor}`}
                width={0.4}
                height={1.2}
              />
              <path
                id="_\uD328\uC2A4_510-13"
                className={`st1${titleColor}`}
                d="M49.9,40.4h0.9l-0.1-8.2H33.3v8.1h0.6L35,34h13.9L49.9,40.4L49.9,40.4L49.9,40.4z"
              />
              <rect
                id="_\uC0AC\uAC01\uD615_107-13"
                x={37.4}
                y={31}
                className={`st1${titleColor}`}
                width={9.1}
                height={0.8}
              />
              <rect
                id="_\uC0AC\uAC01\uD615_108-13"
                x={37.4}
                y={31.8}
                className={`st1${titleColor}`}
                width={9.1}
                height={0.6}
              />
              <path
                id="_\uD328\uC2A4_511-13"
                className={`re${titleColor}`}
                d="M18.2,24.7c0-0.5-0.4-0.9-0.9-0.9l24.6,0.1l-0.7,1.7h-24C17.7,25.6,18.1,25.2,18.2,24.7 L18.2,24.7L18.2,24.7z"
              />
              <path
                id="_\uD328\uC2A4_512-13"
                className={`re${titleColor}`}
                d="M17.1,23.8h0.2C17.2,23.8,17.1,23.8,17.1,23.8z"
              />
              <path
                id="_\uD328\uC2A4_515-13"
                className={`re${titleColor}`}
                d="M17.1,23.8c0,0,0.1,0,0.2,0l0,0c0.5,0,0.9,0.4,0.9,0.9l0,0l0,0c0,0.5-0.4,0.9-0.9,0.9 c-0.1,0-0.1,0-0.2,0c-0.5-0.1-0.8-0.5-0.7-1l0,0C16.4,24.1,16.7,23.9,17.1,23.8L17.1,23.8L17.1,23.8z"
              />
              <line
                id="_\uC120_103-13"
                className={`re${titleColor}`}
                x1={39.1}
                y1={30.9}
                x2={38.8}
                y2={25.6}
              />
            </g>
            <ellipse
              id="_\uD0C0\uC6D0_34-13"
              className={`st1${titleColor}`}
              cx={17.2}
              cy={24.7}
              rx={4.4}
              ry={4.3}
            />
            <path
              id="_\uD328\uC2A4_516-13"
              className={`st1${titleColor}`}
              d="M12.7,23.8c-0.6-0.5-0.7-1.4-0.2-2l0,0l1,0.8C13.2,22.8,12.9,23.3,12.7,23.8L12.7,23.8 L12.7,23.8z"
            />
            <path
              id="_\uD328\uC2A4_517-13"
              className={`st1${titleColor}`}
              d="M17.3,20.4v-1.2c-0.8,0-1.5,0.6-1.5,1.4C16.3,20.5,16.8,20.4,17.3,20.4z"
            />
            <path
              id="_\uD328\uC2A4_518-13"
              className={`st1${titleColor}`}
              d="M13.5,26.8l-1.1,0.6c0.4,0.7,1.3,0.9,2,0.5C14.1,27.6,13.7,27.2,13.5,26.8L13.5,26.8z"
            />
            <path
              id="_\uD328\uC2A4_519-13"
              className={`st1${titleColor}`}
              d="M19.5,20.9l0.6-1c-0.7-0.4-1.6-0.2-2,0.5C18.5,20.5,19,20.7,19.5,20.9L19.5,20.9 L19.5,20.9z"
            />
            <path
              id="_\uD328\uC2A4_520-13"
              className={`st1${titleColor}`}
              d="M21.1,22.6l1-0.6c-0.4-0.7-1.3-0.9-2-0.5C20.6,21.8,20.9,22.1,21.1,22.6L21.1,22.6z"
            />
            <path
              id="_\uD328\uC2A4_521-13"
              className={`st1${titleColor}`}
              d="M21.2,26.5l1,0.6c0.4-0.7,0.2-1.6-0.5-2l0,0C21.7,25.6,21.5,26.1,21.2,26.5L21.2,26.5z"
            />
            <path
              id="_\uD328\uC2A4_522-13"
              className={`st1${titleColor}`}
              d="M21.7,24.7h1.2c0-0.8-0.7-1.5-1.5-1.5C21.6,23.7,21.7,24.2,21.7,24.7z"
            />
            <path
              id="_\uD328\uC2A4_523-13"
              className={`st1${titleColor}`}
              d="M12.8,24.6h-1.2c0,0.8,0.7,1.5,1.5,1.5C12.9,25.6,12.8,25.1,12.8,24.6z"
            />
            <path
              id="_\uD328\uC2A4_524-13"
              className={`st1${titleColor}`}
              d="M15,28.3l-0.6,1c0.7,0.4,1.6,0.2,2-0.5C15.9,28.8,15.4,28.6,15,28.3L15,28.3z"
            />
            <path
              id="_\uD328\uC2A4_525-13"
              className={`st1${titleColor}`}
              d="M19.7,28.2l0.7,1c0.6-0.4,0.8-1.3,0.4-1.9c0,0,0,0,0-0.1C20.5,27.6,20.2,28,19.7,28.2 L19.7,28.2z"
            />
            <path
              id="_\uD328\uC2A4_526-13"
              className={`st1${titleColor}`}
              d="M15,20.9l-0.6-1c-0.7,0.4-0.9,1.3-0.5,2l0,0C14.2,21.5,14.6,21.2,15,20.9L15,20.9L15,20.9 z"
            />
            <path
              id="_\uD328\uC2A4_527-13"
              className={`st1${titleColor}`}
              d="M17.4,29v1.2c0.8,0,1.5-0.6,1.5-1.4C18.4,28.9,17.9,29,17.4,29z"
            />
          </g>
        </svg>
      </div>
    )
  );
};
export default ReclaimerSvg;
