import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const StackedBarChart = ({ title, data, width, height, category }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);
  const [uCategory, setUCategory] = useState<any>();

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        // borderRadius: 15,
        // borderRadiusApplication: "end",
        // borderRadiusWhenStacked: "last",
        columnWidth: "10%",
        barHeight: "50%",
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 20,
            formatter: function (val, opts) {
              return Number(val).toFixed(4);
            },
            style: {
              fontSize: "15px",
              fontWeight: 900,
              color: "#FFF",
            },
          },
        },
      },
    },
    // stroke: {
    //   // width: 1,
    //   // colors: ["#fff"],
    // },
    title: {
      text: title,
      align: "center",
      style: {
        fontSize: "22px",
        // color: "#262626",

        color: "#FFF",
        fontFamily: "Noto Sans KR",
        fontWeight: 600,
      },
    },
    xaxis: {
      categories: uCategory,
      labels: {
        formatter: function (val) {
          return val;
        },
        style: {
          colors: ["#FFF"],
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        style: {
          colors: "#FFF",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      y: {
        // formatter: function (val) {
        //   // return val + "K";
        //   return val;
        // },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetX: 35,
      // offsetY: 2,
      labels: {
        colors: "#FFF",
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#FFF",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    colors: ["#0487D9", "#F29F05", "#105781"],
  };

  useEffect(() => {
    let _c = [];
    for (let c of category) {
      _c.push(c.toUpperCase());
    }
    setUCategory(_c);
    setSeries(data);
  }, [data, category]);

  return (
    series && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Chart
          options={chartOptions}
          type="bar"
          series={series}
          width={width}
          height={height}
        />
      </div>
    )
  );
};

export default StackedBarChart;
