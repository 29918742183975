import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const BubbleChart = ({
  series,
  width,
  height,
  dateType,
  setModal,
  setModalParam,
}: any) => {
  // const navigate = useNavigate();
  // const onDetail = (record: any) => {
  //   navigate("/shareData/history", { state: record });
  // };

  const chartOptions: ApexOptions = {
    plotOptions: {
      bubble: {
        minBubbleRadius: 20,
        // maxBubbleRadius: 30,
        // minBubbleRadius: 30,
        maxBubbleRadius: 50,
      },
    },

    chart: {
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex === -1) {
            return;
          }
          setModal();
          const param = {
            ...config.config.series[config.seriesIndex],
            dateType,
          };
          setModalParam(param);
        },
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: function (val, opts) {
          const index = opts.seriesIndex;
          const value = opts.w.config.series[index].data[0][2];
          return value;
        },
      },
      z: {
        formatter: function (val) {
          return null;
        },
      },
    },
    xaxis: {
      min: 0,
      // max: 20,
    },
    yaxis: {
      min: 0,
      // min: 0,
      // max: 6,
    },
  };

  return (
    series && (
      <div style={{ width: "100%" }}>
        <Chart
          style={{
            width: "100%",
          }}
          overflow="hidden"
          type="bubble"
          options={chartOptions}
          series={series}
          height={height}
        />
      </div>
    )
  );
};

export default BubbleChart;
