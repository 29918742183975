import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const AreaChart = ({ series, width, height }: any) => {
  const chartOptions: ApexOptions = {
    xaxis: {
      labels: {
        style: {
          fontFamily: "Noto Sans KR",
          // colors: "#F0F0F0",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          // colors: "#F0F0F0",
          //   fontSize: "16px",
          fontFamily: "Noto Sans KR",
        },
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    colors: ["#548697", "#75bdc4", "#86d9db"],
  };

  return (
    series && (
      <Chart
        options={chartOptions}
        series={series}
        width={width}
        height={height}
      />
    )
  );
};

export default AreaChart;
